import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { selectedContentsState } from "../recoil/atoms/generalState";

export default function Auth() {
  const navigate = useNavigate();
  const setSelectedContents = useSetRecoilState(selectedContentsState);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const authParam = searchParams.get('member_id');
    if (authParam) {
      const memberId = atob(decodeURIComponent(authParam));
      window.localStorage.setItem('auth', memberId);
    }

    setSelectedContents(window.localStorage.getItem("last_landing")?.slice(1) ?? "personality");
    navigate('/pet-selection');
  }, []);
}