// 강아지 성향 검사 결과

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../components-v2/Button";
import { useRecoilState, useSetRecoilState } from "recoil";
import { testResultState } from "../recoil/atoms/inputState";
import { loadingState } from "../recoil/atoms/generalState";
import axios from "axios";
import Slider from "react-slick";

export default function PersonalityResult() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [existsResultPet, setExistsResultPet] = useState(null);

  const location = useLocation();
  const selectMyPet = location.state?.selectMyPet || existsResultPet;

  const [testResult, setTestResult] = useRecoilState(testResultState);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    const qsPetId = searchParams.get('petId');
    if (qsPetId) {
      loadExistsResult(qsPetId);
    }
  }, []);

  const loadExistsResult = async (qsPetId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://pppet-ent-server.vercel.app/exists-result/${qsPetId}/personality`);
      const data = response.data;
      setTestResult([data.result]);
      setExistsResultPet(data.pet);
    } catch (error) {
      console.info('Perhaps a timing issue may be causing the error if the scan has just finished.');
    }
    setLoading(false);
  };

  const handleShareButtonClick = (e) => {
    window.Kakao.Share.sendDefault({
      objectType: 'text',
      text:
        '[펫펫펫 댕BTI]\n우리 아이의 성격 유형 검사 결과를 확인해보세요!',
      link: {
        mobileWebUrl: `https://ent.pppet.shop/personality-result?petId=${selectMyPet._id}`,
        webUrl: `https://ent.pppet.shop/personality-result?petId=${selectMyPet._id}`,
      },
    });
  };

  return (
    <div>
      <div className="w-full">
        <img src={`/image/dbti/${testResult[0]?.personalityType}.png`} className="w-full" />
      </div>
      <div className="px-[20px] bg-[#FAFAFA]">
        <div className="mt-[-30px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[30px] pb-[10px] px-[20px] relative">
          <div className="text-center text-[#5D80FD] text-[20px] font-[700] leading-[39.5px]">
            {selectMyPet?.name}의 성격유형은
          </div>
          <div className="text-center text-[#1E274D] text-[40px] font-[800] leading-[44px]">
            {testResult[0]?.personalityTypeName}<br />
            #{testResult[0]?.personalityType}
          </div>
          {/* Title */}
          <div className="pt-[58px] text-[20px] font-[700] leading-[28px] text-[#1E274D]">
            {testResult[0]?.signatureContents[0]?.title}
          </div>
          {/* Description */}
          <div
            className="mt-[34px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.signatureContents[0]?.text.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="pt-[85px] text-[#1E274D] text-[20px] font-[700] leading-[28px]">
          우리 {selectMyPet?.name}의<br/><span className="bg-[#E2F6FF] rounded-[11px] px-[3px]">이런 점도 주목</span>해 보세요.
        </div>

        <div className="mt-[16px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[30px] pb-[10px] px-[20px] relative">
          {/* Title */}
          <div className="text-[20px] font-[700] leading-[28px]">
            {testResult[0]?.signatureContents[1]?.title}
          </div>
          {/* Description */}
          <div
            className="mt-[25px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.signatureContents[1]?.text.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="pt-[64px] text-[#1E274D] text-[20px] font-[700] leading-[28px]">
          {selectMyPet?.name}의 성향을<br/><span className="bg-[#E2F6FF] rounded-[11px] px-[3px]">자세하게 알아볼 준비 되셨나요?</span>
        </div>

        <div className="mt-[16px] pb-[20px]">
          <div className="w-full mt-4 text-center text-[16px]">
            <div className="w-full py-[9px] bg-[#f8f8f8] border-solid border-[#ddd] text-center font-[700] text-[14px] leading-[22px] text-[#1E274D] border border-solid border-[#ddd] rounded-t-[23px]">
              성향 유형
            </div>
            <ul className="w-full grid grid-cols-2 text-[#CCC] bg-white">
              <li className="w-full p-[12px] box-border border-x-[1px] border-b-[1px] border-solid border-[#ddd] grid grid-cols-2 gap-x-[8px] text-center text-[#ccc] font-normal">
                <p className={`py-1 ${testResult[0]?.personalityType[0] !== 'A' ? 'text-white bg-[#FF5900] rounded-[23px]' : ''}`}>내향적</p>
                <p className={`py-1 ${testResult[0]?.personalityType[0] === 'A' ? 'text-white bg-[#FF5900] rounded-[23px]' : ''}`}>외향적</p>
              </li>
              <li className="w-full p-[12px] box-border border-r-[1px] border-b-[1px] border-solid border-[#ddd] grid grid-cols-2 gap-x-[8px] text-center text-[#ccc] font-normal">
                <p className={`py-1 ${testResult[0]?.personalityType[1] !== 'S' ? 'text-white bg-[#5D80FD] rounded-[23px]' : ''}`}>독립적</p>
                <p className={`py-1 ${testResult[0]?.personalityType[1] === 'S' ? 'text-white bg-[#5D80FD] rounded-[23px]' : ''}`}>사회적</p>
              </li>
              <li className="w-full p-[12px] box-border border-x-[1px] border-b-[1px] border-solid border-[#ddd] grid grid-cols-2 gap-x-[8px] text-center text-[#ccc] font-normal rounded-bl-[23px]">
                <p className={`py-1 ${testResult[0]?.personalityType[2] !== 'T' ? 'text-white bg-[#FDDC00] rounded-[23px]' : ''}`}>예민한</p>
                <p className={`py-1 ${testResult[0]?.personalityType[2] === 'T' ? 'text-white bg-[#FDDC00] rounded-[23px]' : ''}`}>안정적인</p>
              </li>
              <li className="w-full p-[12px] box-border border-r-[1px] border-b-[1px] border-solid border-[#ddd] grid grid-cols-2 gap-x-[8px] text-center text-[#ccc] font-normal rounded-br-[23px]">
                <p className={`py-1 ${testResult[0]?.personalityType[3] === 'C' ? 'text-white bg-[#1E274D] rounded-[23px]' : ''}`}>호기심</p>
                <p className={`py-1 ${testResult[0]?.personalityType[3] !== 'C' ? 'text-white bg-[#1E274D] rounded-[23px]' : ''}`}>신중한</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-[64px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[24px] pb-[10px] px-[20px] relative">
          {/* Title */}
          <div className="flex">
            <div className="text-white bg-[#FF5900] rounded-[23px] text-[16px] font-[500] leading-[22px] py-[3px] px-[10px]">
              {testResult[0]?.personalityType[0] === 'A' ? '외향적' : '내향적'}
            </div>
          </div>
          <div className="mt-[5px] text-[20px] font-[700] leading-[28px] inline-flex">
            {testResult[0]?.contents[0]?.title}
          </div>
          {/* Description */}
          <div
            className="mt-[25px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.contents[0]?.text.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="mt-[20px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[24px] pb-[10px] px-[20px] relative">
          {/* Title */}
          <div className="flex">
            <div className="text-white bg-[#5D80FD] rounded-[23px] text-[16px] font-[500] leading-[22px] py-[3px] px-[10px]">
              {testResult[0]?.personalityType[1] === 'S' ? '사회적' : '독립적'}
            </div>
          </div>
          <div className="mt-[5px] text-[20px] font-[700] leading-[28px] inline-flex">
            {testResult[0]?.contents[1]?.title}
          </div>
          {/* Description */}
          <div
            className="mt-[25px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.contents[1]?.text.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="mt-[20px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[24px] pb-[10px] px-[20px] relative">
          {/* Title */}
          <div className="flex">
            <div className="text-white bg-[#FDDC00] rounded-[23px] text-[16px] font-[500] leading-[22px] py-[3px] px-[10px]">
              {testResult[0]?.personalityType[2] === 'T' ? '안정적인' : '예민한'}
            </div>
          </div>
          <div className="mt-[5px] text-[20px] font-[700] leading-[28px] inline-flex">
            {testResult[0]?.contents[2]?.title}
          </div>
          {/* Description */}
          <div
            className="mt-[25px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.contents[2]?.text.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="mt-[20px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[24px] pb-[10px] px-[20px] relative">
          {/* Title */}
          <div className="flex">
            <div className="text-white bg-[#1E274D] rounded-[23px] text-[16px] font-[500] leading-[22px] py-[3px] px-[10px]">
              {testResult[0]?.personalityType[3] === 'C' ? '호기심' : '신중한'}
            </div>
          </div>
          <div className="mt-[5px] text-[20px] font-[700] leading-[28px] inline-flex">
            {testResult[0]?.contents[3]?.title}
          </div>
          {/* Description */}
          <div
            className="mt-[25px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.contents[3]?.text.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="mt-[20px]">
          {
            location.state?.selectMyPet?._id !== existsResultPet?._id ? (
              <Button
                onClick={() => navigate('/relationship')}
              >
                다시 해보기
              </Button>
            ) : (
              <Button
                onClick={handleShareButtonClick}
                additionalClass="flex justify-center gap-x-[8px]"
              >
                <img src="/image/kakaotalk-icon.png" className="w-[24px] h-[24px]" />
                <span>
                  결과 공유하기
                </span>
              </Button>
            )
          }
        </div>
      </div>

      <div className="bg-[#FAFAFA]">
        <div className="w-full pt-[30px] px-[20px]">
          <div className="w-full flex items-center text-[1rem]">
            <img
              src={process.env.PUBLIC_URL + "./image/icon/dog.png"}
              alt="dog"
            />
            <p className="ml-3 font-semibold">이런 검사는 어떠세요?</p>
          </div>
        </div>
        <div className="py-[12px]">
          <Slider className="slider variable-width" dots={false} infinite={true} speed={500} slidesToShow={1} slidesToScroll={1} variableWidth={true} arrows={false}>
            <div style={{ width: 330 }}>
              <a href="/relationship">
                <img className="ml-4" src="/image/recommendation-boxs/relationship.png" width={316} />
              </a>
            </div>
            <div style={{ width: 330 }}>
              <a href="/health">
                <img className="ml-4" src="/image/recommendation-boxs/health.png" width={316} />
              </a>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
