import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import Auth from "./page/Auth";
import NotFoundPage from "./components/NotFoundPage";
import PersonalityLanding from "./page/PersonalityLanding";
import RelationshipLanding from "./page/RelationshipLanding";
import HealthLanding from "./page/HealthLanding";
import Root from "./page/Root";
import PetSelection from "./page/PetSelection";
import PetRegister from "./page/PetRegister";
import MeasureQuestions from "./page/MeasureQuestions";
import PersonalityResult from "./page/PersonalityResult";
import RelationshipResult from "./page/RelationshipResult";
import HealthResult from "./page/HealthResult";
import HealthQuestions from "./page/HealthQuestions";
import RelationshipDogOwner from "./page/RelationshipDogOwner";
import PetEdit from "./page/PetEdit";

function App() {
  // const [LandingHeight, setLandingHeight] = useState(0);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <RecoilRoot>
          <RecoilNexus />
          <Root />
        </RecoilRoot>
      ),
      children: [
        {
          index: true,
          element: <PersonalityLanding />,
        },
        {
          path: "/auth",
          element: <Auth />,
        },
        {
          path: "/personality",
          element: <PersonalityLanding />,
        },
        {
          path: "/relationship",
          element: <RelationshipLanding />,
        },
        {
          path: "/health",
          element: <HealthLanding />,
        },
        {
          path: "/pet-selection",
          element: <PetSelection />,
        },
        {
          path: "/pet-register",
          element: <PetRegister />,
        },
        {
          path: "/pet-edit",
          element: <PetEdit />,
        },
        {
          path: "/relationship-dog-owner",
          element: <RelationshipDogOwner />,
        },
        {
          path: "/measure-questions",
          element: <MeasureQuestions />,
        },
        {
          path: "/health-questions",
          element: <HealthQuestions />,
        },
        {
          path: "/personality-result",
          element: <PersonalityResult />,
        },
        {
          path: "/relationship-result",
          element: <RelationshipResult />,
        },
        {
          path: "/health-result",
          element: <HealthResult />,
        },
        { path: "*", element: <NotFoundPage /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
