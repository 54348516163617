import React from "react";
import {
  inputPetBirthDayState,
  inputPetBirthMonthState,
  inputPetBirthYearState,
  inputPetNameState,
  inputPetOwnerBirthDayState,
  inputPetOwnerBirthMonthState,
  inputPetOwnerBirthYearState,
  inputPetOwnerMbtiState,
  inputPetOwnerNameState,
  inputPetTypeState,
  inputPetWeightState,
} from "../recoil/atoms/inputState";
import { useRecoilState } from "recoil";

export default function InputText({ inputId, labelText, placeholderText }) {
  const [inputName, setInputName] = useRecoilState(inputPetNameState);
  const [inputOwnerName, setInputOwnerName] = useRecoilState(
    inputPetOwnerNameState
  );
  const [inputOwnerBirthYear, setInputOwnerBirthYear] = useRecoilState(
    inputPetOwnerBirthYearState
  );
  const [inputOwnerBirthMonth, setInputOwnerBirthMonth] = useRecoilState(
    inputPetOwnerBirthMonthState
  );
  const [inputOwnerBirthDay, setInputOwnerBirthDay] = useRecoilState(
    inputPetOwnerBirthDayState
  );
  const [inputOwnerMbti, setInputOwnerMbti] = useRecoilState(
    inputPetOwnerMbtiState
  );
  const [inputType, setInputType] = useRecoilState(inputPetTypeState);
  const [inputBirthYear, setInputBirthYear] = useRecoilState(
    inputPetBirthYearState
  );
  const [inputBirthMonth, setInputBirthMonth] = useRecoilState(
    inputPetBirthMonthState
  );
  const [inputBirthDay, setInputBirthDay] = useRecoilState(
    inputPetBirthDayState
  );
  const [inputWeight, setInputWeight] = useRecoilState(inputPetWeightState);

  const handleChange = (e) => {
    if (e.target.id === "dogNameInput") {
      setInputName(e.target.value);
    } else if (e.target.id === "dogTypeInput") {
      setInputType(e.target.value);
    } else if (e.target.id === "dogBirthYearInput") {
      if (!isNaN(Number(e.target.value)) && e.target.value.length <= 4) {
        setInputBirthYear(e.target.value);
      }
    } else if (e.target.id === "dogBirthMonthInput") {
      if (!isNaN(Number(e.target.value)) && e.target.value.length <= 2) {
        setInputBirthMonth(e.target.value);
      }
    } else if (e.target.id === "dogBirthDayInput") {
      if (!isNaN(Number(e.target.value)) && e.target.value.length <= 2) {
        setInputBirthDay(e.target.value);
      }
    } else if (e.target.id === "dogWeightInput") {
      if (!isNaN(Number(e.target.value)) && e.target.value.length <= 8) {
        setInputWeight(e.target.value);
      }
    } else if (e.target.id === "dogOwnerInput") {
      setInputOwnerName(e.target.value);
    } else if (e.target.id === "dogOwnerBirthYearInput") {
      if (!isNaN(Number(e.target.value)) && e.target.value.length <= 4) {
        setInputOwnerBirthYear(e.target.value);
      }
    } else if (e.target.id === "dogOwnerBirthMonthInput") {
      if (!isNaN(Number(e.target.value)) && e.target.value.length <= 2) {
        setInputOwnerBirthMonth(e.target.value);
      }
    } else if (e.target.id === "dogOwnerBirthDayInput") {
      if (!isNaN(Number(e.target.value)) && e.target.value.length <= 2) {
        setInputOwnerBirthDay(e.target.value);
      }
    } else if (e.target.id === "dogOwnerMbtiInput") {
      setInputOwnerMbti(e.target.value.toUpperCase());
    }
  };

  return (
    <>
      {inputId !== "dogBirthYearInput" &&
      inputId !== "dogBirthMonthInput" &&
      inputId !== "dogBirthDayInput" &&
      inputId !== "dogOwnerBirthYearInput" &&
      inputId !== "dogOwnerBirthMonthInput" &&
      inputId !== "dogOwnerBirthDayInput" ? (
        <>
          <label
            htmlFor={inputId}
            className="text-[14px] font-[500] leading-[20px]"
          >
            {labelText}
          </label>
          <input
            autocomplete="off"
            className="rounded-[23px] w-full py-[8px] px-[23px] box-border mt-[4px] mb-[30px] border border-solid border-[#ddd] placeholder:font-normal placeholder:text-[#ccc] outline-none focus:ring-0 text-[14px] font-[500] leading-[22px] focus:border-[1px] focus:border-[#5D80FD]"
            type="text"
            id={inputId}
            placeholder={placeholderText}
            value={
              inputId === "dogNameInput"
                ? inputName
                : inputId === "dogTypeInput"
                ? inputType
                : inputId === "dogOwnerInput"
                ? inputOwnerName
                : inputId === "dogOwnerMbtiInput"
                ? inputOwnerMbti
                : inputWeight
            }
            onChange={handleChange}
          />
        </>
      ) : (
        <div className="relative">
          <input
            autocomplete="off"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundPosition: "1rem center",
            }}
            className={`py-[8px] text-center w-full box-border mt-[4px] border-[1px] border-solid border-[#ddd] rounded-full placeholder:text-[#ccc] outline-none focus:ring-0 text-[14px] font-[500] leading-[22px] focus:border-[1px] focus:border-[#5D80FD]`}
            type="text"
            id={inputId}
            placeholder={placeholderText}
            value={
              inputId === "dogBirthYearInput"
                ? inputBirthYear
                : inputId === "dogBirthMonthInput"
                ? inputBirthMonth
                : inputId === "dogBirthDayInput"
                ? inputBirthDay
                : inputId === "dogOwnerBirthYearInput"
                ? inputOwnerBirthYear
                : inputId === "dogOwnerBirthMonthInput"
                ? inputOwnerBirthMonth
                : inputId === "dogOwnerBirthDayInput"
                ? inputOwnerBirthDay
                : ''
            }
            onChange={handleChange}
          />
        </div>
      )}
    </>
  );
}
