import React, { useEffect, useState } from "react";
import Tabs from "../components/Tabs";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  selectedTabState,
  selectedTestState,
  testResultState,
} from "../recoil/atoms/inputState";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { authState } from "../recoil/atoms/authState";
import {
  initScrollTriggerState,
  loadingState,
} from "../recoil/atoms/generalState";
import { retryPromise } from "../utilities/common";
import ProgressBar from "../components/ProgressBar";

export default function MeasureQuestions() {
  const setInitScrollTrigger = useSetRecoilState(initScrollTriggerState);
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);
  const [testState, setTestState] = useRecoilState(selectedTestState);
  const setTestResult = useSetRecoilState(testResultState);
  const userAuthState = useRecoilValue(authState);
  const setLoading = useSetRecoilState(loadingState);
  const [focusQuestion, setFocusQuestion] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const content = location.state?.content || "";
  const selectMyPet = location.state?.selectMyPet || "";
  const dogOwnerName = location.state?.inputOwnerName || "";
  const dogOwnerAge = location.state?.inputOwnerAge || "";
  const dogOwnerBirth = location.state?.inputOwnerBith || "";
  const dogOwnerSex = location.state?.inputOwnerSex || "";
  const dogOwnerRole = location.state?.inputOwnerRole || "";
  const dogOwnerMbti = location.state?.inputOwnerMbti || "";

  const queryClient = useQueryClient();

  useEffect(() => {
    setTestResult([]);
    setSelectedTab(0);
  }, []);

  useEffect(() => {
    setInitScrollTrigger(true);
  }, [selectedTab]);

  const mutation = useMutation(
    async (data) => {
      setLoading(true);
      const res = await retryPromise(() => {
        return axios.post(
          `https://pppet-ent-server.vercel.app/questions/${content}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              auth: userAuthState,
            },
          }
        );
      });
      const result = res.data;
      setTestResult([result]);
      setLoading(false);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["test", userAuthState]);
      },
    }
  );

  const handleButtonClick = () => {
    if (content !== "health" && selectedTab < 3) {
      setSelectedTab(selectedTab + 1);
    } else {
      const submitTestState = [...testState];
      const sendTestState = submitTestState.sort((a, b) => a.questionNum - b.questionNum).map((item) => item.state);

      mutation.mutate({
        petId: selectMyPet._id,
        dogOwnerName: dogOwnerName,
        dogOwnerAge: Number(dogOwnerAge),
        dogOwnerBirth,
        dogOwnerSex: dogOwnerSex
          ? dogOwnerSex === "남자"
            ? "M"
            : "F"
          : undefined,
        dogOwnerRole,
        dogOwnerMbti: dogOwnerMbti,
        answers: sendTestState,
      });

      navigate(`/${content}-result?petId=${selectMyPet._id}`, { state: { selectMyPet, ownerName: dogOwnerName } });

      setSelectedTab(0);
      // testState 비워주기.
      setTestState([]);
    }
  };

  const { error, data: test } = useQuery(
    ["test", content],
    async () => {
      return retryPromise(async () => {
        const res = await axios.get(
          `https://pppet-ent-server.vercel.app/questions/${content}`
        );
        return res.data.questions;
      });
    },
    { staleTime: 1000 * 5 }
  );

  // 질문의 총 갯수를 구하는 함수입니다.
  const carculateQuestionNum = (test) => {
    const flattened = test?.flat();
    const count = flattened?.length;
    return count;
  };

  const allQuestionNum = carculateQuestionNum(test);

  return (
    <div className="w-full pb-[20px] box-border text-[0.9rem]">
      <div className="w-full max-w-[720px] px-[20px] pt-[13px] box-border bg-white fixed top-0 left-[50%] transform -translate-x-1/2 z-[9999]">
        <div className="w-full border-b-[1px] border-solid border-[#ddd]">
          <div className="flex items-center justify-between">
            <button>
              <img
                onClick={(e) => {
                  e.preventDefault();
                  setTestState([]);
                  navigate(-1);
                }}
                src={process.env.PUBLIC_URL + `/image/icon/back.png`}
                className="w-[24px] h-[24px]"
                alt="back"
              />
            </button>

            <h1 className="text-[24px] font-[700] leading-[32px]">
              {content === "personality"
                ? "댕BTI 검사"
                : "반려가족 궁합 검사"}
            </h1>

            <div className="flex items-center text-[16px] font-[300]">
              <p className="font-[500]">
                {testState.length}
              </p>
              <p>/</p>
              <p>{allQuestionNum}</p>
            </div>
          </div>
          <ProgressBar
            petMedicalExamination={allQuestionNum}
            count={testState.length}
          />
        </div>
      </div>

      <div className="w-full mt-[96px] pb-3">
        <div>
          <Tabs
            error={error}
            test={test}
            content={content}
            focusQuestion={focusQuestion}
            setFocusQuestion={setFocusQuestion}
            allQuestionNum={allQuestionNum}
          />
        </div>

        <div className="mt-[62px] px-[20px] h-[55px] flex items-center gap-x-[8px] text-[16px]">
          <button
            className={
              selectedTab === 0
                ? "w-1/3 h-full rounded-[23px] bg-[#ddd] border-[1px] border-solid border-[#ccc] text-[#ABABAB]"
                : "w-1/3 h-full rounded-[23px] bg-[#1E274D] text-white"
            }
            disabled={selectedTab === 0 ? true : false}
            onClick={(e) => {
              e.preventDefault();

              setSelectedTab((prev) => prev - 1);
            }}
          >
            이전
          </button>

          {selectedTab === 0 ? (
            <button
              disabled={
                test && test[0]?.length <= testState.length ? false : true
              }
              onClick={handleButtonClick}
              className={`${
                test && test[0]?.length <= testState.length
                  ? "bg-[#1E274D] sm:bg-pos-custom1-2 bg-pos-custom"
                  : "bg-[#ddd] sm:bg-pos-custom2-2 bg-pos-custom2"
              } w-2/3 h-full p-3 box-border rounded-[23px] border-[1px] border-solid border-[#ccc] transition-all duration-700`}
            >
              {test && test[0]?.length <= testState.length ? (
                <p className="text-white">
                  {content === "health" ? "결과 확인하기" : "다음으로"}
                </p>
              ) : (
                <p className="text-[#ABABAB]">모든 질문에 답해주세요.</p>
              )}
            </button>
          ) : selectedTab === 1 ? (
            <button
              disabled={
                test && test[0]?.length + test[1]?.length <= testState.length
                  ? false
                  : true
              }
              onClick={handleButtonClick}
              className={`${
                test && test[0]?.length + test[1]?.length <= testState.length
                  ? "bg-[#1E274D] sm:bg-pos-custom1-2 bg-pos-custom"
                  : "bg-[#ddd] sm:bg-pos-custom2-2 bg-pos-custom2"
              } w-2/3 h-full p-3 box-border rounded-[23px] border-[1px] border-solid border-[#ccc] transition-all duration-700`}
            >
              {test && test[0]?.length + test[1]?.length <= testState.length ? (
                <p className="text-white">다음으로</p>
              ) : (
                <p className="text-[#ABABAB]">모든 질문에 답해주세요.</p>
              )}
            </button>
          ) : selectedTab === 2 ? (
            <button
              disabled={
                test &&
                test[0]?.length + test[1]?.length + test[2]?.length <=
                  testState.length
                  ? false
                  : true
              }
              onClick={handleButtonClick}
              className={`${
                test &&
                test[0]?.length + test[1]?.length + test[2]?.length <=
                  testState.length
                  ? "bg-[#1E274D] sm:bg-pos-custom1-2 bg-pos-custom"
                  : "bg-[#ddd] sm:bg-pos-custom2-2 bg-pos-custom2"
              } w-2/3 h-full p-3 box-border rounded-[23px] border-[1px] border-solid border-[#ccc] transition-all duration-700`}
            >
              {test &&
              test[0]?.length + test[1]?.length + test[2]?.length <=
                testState.length ? (
                <p className="text-white">다음으로</p>
              ) : (
                <p className="text-[#ABABAB]">모든 질문에 답해주세요.</p>
              )}
            </button>
          ) : (
            <button
              disabled={
                test &&
                test[0]?.length +
                  test[1]?.length +
                  test[2]?.length +
                  test[3]?.length <=
                  testState.length
                  ? false
                  : true
              }
              onClick={handleButtonClick}
              className={`${
                test &&
                test[0]?.length +
                  test[1]?.length +
                  test[2]?.length +
                  test[3]?.length <=
                  testState.length
                  ? "bg-[#1E274D] sm:bg-pos-custom3-2 bg-pos-custom3"
                  : "bg-[#ddd] sm:bg-pos-custom2-2 bg-pos-custom2"
              } w-2/3 h-full p-3 box-border rounded-[23px] border-[1px] border-solid border-[#ccc] transition-all duration-700`}
            >
              {test &&
              test[0]?.length +
                test[1]?.length +
                test[2]?.length +
                test[3]?.length <=
                testState.length ? (
                <p className="text-white">결과 확인하기</p>
              ) : (
                <p className="text-[#ABABAB]">모든 질문에 답해주세요.</p>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
