import React, { useEffect } from "react";
import InputText from "../components/InputText";
import InputCheckbox from "../components/InputCheckbox";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  inputPetBirthDayState,
  inputPetBirthMonthState,
  inputPetBirthYearState,
  inputPetNameState,
  inputPetSelectBreedType,
  inputPetSelectNeutralization,
  inputPetSelectSex,
  inputPetTypeState,
  inputPetWeightState,
} from "../recoil/atoms/inputState";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import axios from "axios";
import { authState } from "../recoil/atoms/authState";
import { loadingState, selectedContentsState } from "../recoil/atoms/generalState";
import { retryPromise } from "../utilities/common";
import { selectedPetState } from "../recoil/atoms/petState";

// Query Parameter로 진행
export default function PetEdit() {
  const setLoading = useSetRecoilState(loadingState);
  const selectedPetStateValue = useRecoilValue(selectedPetState);

  const breedType = ["소형견", "중형견", "대형견"];
  const sexType = ["남아", "여아"];
  const yesOrNo = ["예", "아니오"];

  const selectedContents = useRecoilValue(selectedContentsState);

  const [inputName, setInputName] = useRecoilState(inputPetNameState);
  const [inputType, setInputType] = useRecoilState(inputPetTypeState);
  const [inputBirthYear, setInputBirthYear] = useRecoilState(
    inputPetBirthYearState
  );
  const [inputBirthMonth, setInputBirthMonth] = useRecoilState(
    inputPetBirthMonthState
  );
  const [inputBirthDay, setInputBirthDay] = useRecoilState(
    inputPetBirthDayState
  );
  const [inputWeight, setInputWeight] = useRecoilState(inputPetWeightState);
  const [inputSelectSex, setInputSelectSex] = useRecoilState(inputPetSelectSex);
  const [inputSelectNeutralization, setInputSelectNeutralization] =
    useRecoilState(inputPetSelectNeutralization);
  const [inputSelectBreedType, setInputSelectBreedType] = useRecoilState(inputPetSelectBreedType);

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const userAuthState = useRecoilValue(authState);

  useEffect(() => {
    if (!selectedContents) {
      navigate(window.localStorage.getItem('last_landing') ?? '/personality');
    }
    setInputName(selectedPetStateValue.name);
    setInputType(selectedPetStateValue.breed);
    setInputBirthYear(selectedPetStateValue.birthday.split('.')[0]);
    setInputBirthMonth(selectedPetStateValue.birthday.split('.')[1]);
    setInputBirthDay(selectedPetStateValue.birthday.split('.')[2]);
    setInputWeight(selectedPetStateValue.weight);
    setInputSelectSex(selectedPetStateValue.sex);
    setInputSelectNeutralization(selectedPetStateValue.neutering);
    setInputSelectBreedType(selectedPetStateValue.breedType);
  }, []);

  const handleButtonClick = async (e) => {
    e.preventDefault();

    // 서버로 펫 데이터 보내기.
    const data = {
      petId: selectedPetStateValue._id,
      petType: "dog",
      name: inputName,
      breed: inputType,
      breedType: inputSelectBreedType,
      sex: inputSelectSex,
      birthday: `${inputBirthYear}.${inputBirthMonth}.${inputBirthDay ? inputBirthDay : 1}`,
      weight: inputWeight,
      neutering: inputSelectNeutralization,
    };
    await retryPromise(async () => {
      setLoading(true);
      await axios.patch(`https://pppet-ent-server.vercel.app/pets`, data, {
        headers: {
          "Content-Type": "application/json",
          auth: userAuthState,
        },
      });
      setLoading(false);
    })

    setInputName("");
    setInputType("");
    setInputBirthYear("");
    setInputBirthMonth("");
    setInputBirthDay("");
    setInputWeight("");
    setInputSelectSex("");
    setInputSelectNeutralization("");
    setInputSelectBreedType("");

    navigate("/pet-selection");
  };

  return (
    <div className="w-full px-[20px] py-[24px] box-border">
      <h1 className="text-[25px] leading-[38px] font-[700]">
        강아지의 기본 정보를<br/>입력해주세요.
      </h1>

      <form className="w-full mt-[36px]">
        <InputText
          inputId="dogNameInput"
          labelText="이름"
          placeholderText="초코"
        />

        <InputText
          inputId="dogTypeInput"
          labelText="견종"
          placeholderText="말티즈"
        />

        <InputCheckbox inputTitle="견종 크기" datas={breedType} defaultValue={selectedPetStateValue.breedType}/>

        <div className="text-[14px] font-[500] leading-[20px]">생년월일</div>
        <div className="grid grid-cols-3 gap-x-[8px]">
          <InputText inputId="dogBirthYearInput" placeholderText="태어난 연도"/>
          <InputText inputId="dogBirthMonthInput" placeholderText="태어난 월"/>
          <InputText inputId="dogBirthDayInput" placeholderText="태어난 일"/>
        </div>
        <p className={'mb-[30px] mt-[4px] text-[#888] leading-[20px] text-[14px] font-[500]'}>* 정확한 나이를 모르시면 월까지 입력해주세요.</p>

        <InputCheckbox inputTitle="성별" datas={sexType} defaultValue={selectedPetStateValue.sex}/>

        <InputText
          inputId="dogWeightInput"
          labelText="몸무게"
          placeholderText="강아지 몸무게를 입력해주세요."
        />

        <InputCheckbox inputTitle="중성화 수술 여부" datas={yesOrNo} defaultValue={selectedPetStateValue.neutering}/>

        <Button
          path={path}
          buttonText={
            inputName.length !== 0 &&
            inputType.length !== 0 &&
            inputBirthYear.length !== 0 &&
            inputBirthMonth.length !== 0 &&
            inputWeight.length !== 0 &&
            inputSelectSex.length !== 0 &&
            inputSelectNeutralization.length !== 0 &&
            inputSelectBreedType.length !== 0 ? "수정하기" : "모든 정보를 입력해주세요"
          }
          onClick={handleButtonClick}
          inputName={inputName}
          inputType={inputType}
          inputBirthYear={inputBirthYear}
          inputBirthMonth={inputBirthMonth}
          inputBirthDay={inputBirthDay}
          inputWeight={inputWeight}
          inputSelectSex={inputSelectSex}
          inputSelectNeutralization={inputSelectNeutralization}
          inputBreedType={inputSelectBreedType}
        />
      </form>
    </div>
  );
}
