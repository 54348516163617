import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { lastToastTimeoutState, showToastState } from "../recoil/atoms/toastState";

export default function Toast({
  children,
  type = 'success',
}) {
  const setShowToast = useSetRecoilState(showToastState);
  const lastToastTimeout = useRecoilValue(lastToastTimeoutState);

  const close = () => {
    clearTimeout(lastToastTimeout);
    setShowToast(false);
  }

  return (
    <div className={`w-[335px] h-[60px] px-[25px] py-[19px] rounded-[23px] flex ${type === "success" ? "bg-[#5D80FD]" : "bg-[#FF6A6A]"}`}>
        <div className="text-center text-white text-[14px] font-[500] leading-[22px] w-[261px]">
            {children}
        </div>
        <button onClick={close} className="w-[24px] h-[24px]">
            <img src="/image/x-icon.png" className="w-[24px] h-[24px]" />
        </button>
    </div>
  );
}
