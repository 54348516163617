import { atom } from "recoil";

export const showToastState = atom({
  key: "showToastState",
  default: false,
});

export const toastTextState = atom({
  key: "toastTextState",
  default: "",
});

export const toastTypeState = atom({
  key: "toastTypeState",
  default: "success",
});

export const lastToastTimeoutState = atom({
  key: "lastToastTimeoutState",
  default: null,
})
