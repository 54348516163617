import { atom } from "recoil";

export const selectedContentsState = atom({
  key: "selectedContentsState",
  default: "",
});

export const initScrollTriggerState = atom({
  key: "initScrollTriggerState",
  default: false,
});

export const loadingState = atom({
  key: "loadingState",
  default: false,
});
