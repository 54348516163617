import React from "react";
import { ProgressBarState } from "./StyledComponents";
import { useLocation } from "react-router-dom";

export default function ProgressBar({ petMedicalExamination, count }) {
  const location = useLocation();

  const calculateProgress = (count / petMedicalExamination) * 100;

  return (
    <div className="w-full h-[8px] mt-[16px] mb-[26px] rounded-full bg-[#eee] relative overflow-hidden">
      <ProgressBarState calculateProgress={calculateProgress} />
    </div>
  );
}
