import React, { useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { healthDetailResultModalOpenState } from "../recoil/atoms/inputState";
import Button from "../components-v2/Button";
import { Transition } from "@headlessui/react";

export default function HealthDetailResultModal({
  pet,
  details,
  modalIndex,
}) {
  const setDetailModalOpen = useSetRecoilState(
    healthDetailResultModalOpenState
  );
  const [isTopScroll, setIsTopScroll] = useState(true);

  const detail = details[modalIndex];

  const topRef = useRef(null);

  useEffect(() => {
    // 페이지가 렌더링되고 나면 스크롤을 맨 위로 이동.
    topRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

    const interval = setInterval(handleInterval, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleInterval = () => {
    if (topRef.current.scrollTop > 0) {
      setIsTopScroll(false);
    } else {
      setIsTopScroll(true);
    }
  };

  const scrollTopSmooth = () => {
    topRef.current.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const handleShareButtonClick = (e) => {
    window.Kakao.Share.sendDefault({
      objectType: 'text',
      text:
        '[펫펫펫 스마트 건강검진]\n우리 아이의 스마트 건강검진 결과를 확인해보세요!',
      link: {
        mobileWebUrl: `https://ent.pppet.shop/health-result?petId=${pet._id}`,
        webUrl: `https://ent.pppet.shop/health-result?petId=${pet._id}`,
      },
    });
  };

  return (
    <>
      {(
        <div
          ref={topRef}
          className="w-full h-full box-border fixed top-0 left-0 overflow-scroll scrollbar-hide pb-[4rem] bg-[#FAFAFA]"
        >
          <div className="w-full px-[20px] py-[18px] box-border border-b-[1px] border-solid border-[#eaeaea] flex items-center justify-between">
            <h1 className="text-[25px] font-[700] leading-[24px]">
              {detail.partName}의 상세결과
            </h1>
            <button>
              <img
                onClick={(e) => {
                  e.preventDefault();
                  setDetailModalOpen(false);
                }}
                src={process.env.PUBLIC_URL + `/image/icon/close2.png`}
                className="w-[24px] h-[24px]"
              />
            </button>
          </div>

          <div className="w-full h-full px-4 box-border pb-[4rem]">
            <div className="w-full pt-[40px] box-border text-[#1E274D]">
              <div className="w-full px-[20px] flex items-center">
                <h1 className="mr-[10px] text-[25px] font-[700] leading-[20px]">
                  {pet?.name}의 점수는{" "}
                </h1>
                <span
                  className={`${
                    detail.level === "양호"
                      ? "text-[#90D24D]"
                      : detail.level === "주의"
                      ? "text-[#FDDC00]"
                      : "text-[#FF5900]"
                  } mr-[10px] text-[30px] font-[700] leading-[24px]`}
                >
                  {detail.score}점
                </span>
                <p
                  className={`${
                    detail.level === "양호"
                      ? "bg-[#90D24D]"
                      : detail.level === "주의"
                      ? "bg-[#FDDC00]"
                      : "bg-[#FF5900]"
                  } w-[45px] h-[45px] rounded-full text-white text-[16px] font-[700] leading-[24px] flex items-center justify-center`}
                >
                  {detail.level}
                </p>
              </div>

              <div className="w-full mt-[40px]">
                {detail.contents?.map((content, index) => {
                  if (index === 0) {
                    return (
                      <div className="w-full px-[20px] pb-[20px]">
                        <h1 className="text-[20px] font-[700] leading-[28px]">{content.title}</h1>

                        <div
                          className="pt-[30px] text-[#515151] text-[16px] font-[500] leading-[28px]"
                          dangerouslySetInnerHTML={{
                            __html: content.text.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
                          }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={`${
                          index === detail.contents?.length - 1
                            ? ""
                            : "mb-[50px]"
                        } w-full px-[20px] pt-[25px] pb-[20px] box-border border-[1px] border-solid border-[#ddd] rounded-[23px] bg-white`}
                      >
                        <div
                          className={`${
                            index === 1 ? "bg-[#D8E7FC]" : "bg-[#FFF5CC]"
                          } w-full py-[16px] px-[13px] box-border rounded-[23px]`}
                        >
                          <div className="w-full flex items-center">
                            <img
                              src={
                                process.env.PUBLIC_URL + `/image/icon/check.png`
                              }
                              className="w-[19.5px] h-[14.25px]"
                            />
                            <p className="ml-[11.5px] font-semibold">
                              {content.title} {['', '👍🏻', '💡'][index]}
                            </p>
                          </div>
                        </div>

                        <div 
                          className="mt-[40px] text-[#515151] text-[16px] font-[500] leading-[28px]"
                          dangerouslySetInnerHTML={{
                            __html: content.text
                              .split('.').join('.<br/><br/>')
                              .split('!').join('!<br/><br/>')
                              .split('<br/><br/>').map((v, i) => i == 0 ? `<b>${v}</b>` : v).join('<br/><br/>')
                          }}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>

            <div className="py-[20px]">
              <Button
                onClick={handleShareButtonClick}
                additionalClass="flex justify-center gap-x-[8px]"
              >
                <img src="/image/kakaotalk-icon.png" className="w-[24px] h-[24px]" />
                <span>
                  결과 공유하기
                </span>
              </Button>
            </div>
          </div>
        </div>
      )}
      <Transition
        show={!isTopScroll}
        className="fixed bottom-[20px] right-[15px]"
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <button onClick={scrollTopSmooth}>
          <img src="/image/goto-top-btn.png" className="w-[99px] h-[43px]" />
        </button>
      </Transition>
    </>
  );
}
