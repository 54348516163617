import { getRecoil, setRecoil } from "recoil-nexus"
import { lastToastTimeoutState, showToastState, toastTextState, toastTypeState } from "../recoil/atoms/toastState"

export const openToast = (text, type) => {
  const lastToastTimeout = getRecoil(lastToastTimeoutState);
  if (lastToastTimeout != null) {
    clearTimeout(lastToastTimeout);
  }

  setRecoil(toastTextState, text);
  setRecoil(toastTypeState, type);
  setRecoil(showToastState, true);
  setRecoil(lastToastTimeoutState, setTimeout(() => {
    setRecoil(showToastState, false);
  }, 2500));
}
