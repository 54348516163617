import React, { useEffect } from "react";
import InputText from "../components/InputText";
import InputCheckbox from "../components/InputCheckbox";
import Button from "../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  inputPetOwnerBirthDayState,
  inputPetOwnerBirthMonthState,
  inputPetOwnerBirthYearState,
  inputPetOwnerMbtiState,
  inputPetOwnerNameState,
  inputPetOwnerSelectRole,
  inputPetOwnerSelectSex,
} from "../recoil/atoms/inputState";
import { openToast } from "../utilities/ui";

export default function RelationshipDogOwner() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const content = location.state?.content || "";
  const selectMyPet = location.state?.selectMyPet || "";

  const sexType = ["남자", "여자"];
  const roleType = ["보호자", "대리보호자", "친구"];

  const [inputOwnerName, setInputOwnerName] = useRecoilState(
    inputPetOwnerNameState
  );
  const [inputOwnerBirthYear, setInputOwnerBirthYear] = useRecoilState(
    inputPetOwnerBirthYearState
  );
  const [inputOwnerBirthMonth, setInputOwnerBirthMonth] = useRecoilState(
    inputPetOwnerBirthMonthState
  );
  const [inputOwnerBirthDay, setInputOwnerBirthDay] = useRecoilState(
    inputPetOwnerBirthDayState
  );
  const [inputOwnerSex, setInputOwnerSex] = useRecoilState(
    inputPetOwnerSelectSex
  );
  const [inputOwnerRole, setInputOwnerRole] = useRecoilState(
    inputPetOwnerSelectRole
  );
  const [inputOwnerMbti, setInputOwnerMbti] = useRecoilState(
    inputPetOwnerMbtiState
  );

  useEffect(() => {
    setInputOwnerName("");
    setInputOwnerBirthYear("");
    setInputOwnerBirthMonth("");
    setInputOwnerBirthDay("");
    setInputOwnerSex("");
    setInputOwnerRole("");
    setInputOwnerMbti("");
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();

    const validateMbti = (mbti) => {
      return typeof(mbti) === 'string'
        && mbti.length === 4
        && (mbti[0] === 'I' || mbti[0] === 'E')
        && (mbti[1] === 'N' || mbti[1] === 'S')
        && (mbti[2] === 'T' || mbti[2] === 'F')
        && (mbti[3] === 'J' || mbti[3] === 'P')
    }

    if (!validateMbti(inputOwnerMbti)) {
      openToast('올바른 MBTI를 입력해주세요.', 'error');
      return;
    }

    const inputOwnerAge = new Date().getFullYear() - Number(inputOwnerBirthYear) + 1;

    navigate("/measure-questions", {
      state: {
        content,
        selectMyPet,
        inputOwnerName,
        inputOwnerAge,
        inputOwnerBith: `${inputOwnerBirthYear}.${inputOwnerBirthMonth}.${inputOwnerBirthDay}`,
        inputOwnerSex,
        inputOwnerRole,
        inputOwnerMbti,
      },
    });
  };

  return (
    <div className="w-full px-[20px] py-[24px] box-border">
      <h1 className="text-[25px] leading-[38px] font-[700]">
      보호자의 기본 정보를<br/>입력해주세요.
      </h1>

      <form className="w-full mt-[36px]">
        <InputText
          inputId="dogOwnerInput"
          labelText="이름"
          placeholderText="김유진"
        />

        <div className="text-[14px] font-[500] leading-[20px]">생년월일</div>
        <div className="mb-[30px] grid grid-cols-3 gap-x-[8px]">
          <InputText inputId="dogOwnerBirthYearInput" placeholderText="태어난 연도"/>
          <InputText inputId="dogOwnerBirthMonthInput" placeholderText="태어난 월"/>
          <InputText inputId="dogOwnerBirthDayInput" placeholderText="태어난 일"/>
        </div>

        <InputText
          inputId="dogOwnerMbtiInput"
          labelText="견주 MBTI"
          placeholderText="견주님의 MBTI를 입력해주세요."
        />

        <InputCheckbox inputTitle="성별" datas={sexType} />

        <InputCheckbox inputTitle="관계" datas={roleType} />

        <Button
          path={path}
          buttonText={
            inputOwnerName.length !== 0 &&
            inputOwnerBirthYear.length !== 0 &&
            inputOwnerBirthMonth.length !== 0 &&
            inputOwnerBirthDay.length !== 0 &&
            inputOwnerSex.length !== 0 &&
            inputOwnerRole.length !== 0 &&
            inputOwnerMbti.length !== 0
              ? "입력완료" : "모든 정보를 입력해주세요"
          }
          onClick={handleButtonClick}
          inputOwnerName={inputOwnerName}
          inputOwnerBirthYear={inputOwnerBirthYear}
          inputOwnerBirthMonth={inputOwnerBirthMonth}
          inputOwnerBirthDay={inputOwnerBirthDay}
          inputOwnerSex={inputOwnerSex}
          inputOwnerRole={inputOwnerRole}
          inputOwnerMbti={inputOwnerMbti}
        />
      </form>
    </div>
  );
}
