import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../components-v2/Button";
import { authState } from "../recoil/atoms/authState";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { selectedPetIdState } from "../recoil/atoms/inputState";
import {
  loadingState,
  selectedContentsState,
} from "../recoil/atoms/generalState";
import { calculateAge, retryPromise } from "../utilities/common";
import Slider from "react-slick";
import { getBbtiAlias } from "../utilities/dbti";
import { Transition } from "@headlessui/react";
import { selectedPetState } from "../recoil/atoms/petState";
import { openToast } from "../utilities/ui";

export default function PetSelection() {
  // System states
  const navigate = useNavigate();

  // Global states
  const userAuthState = useRecoilValue(authState);
  const [selectedContents, setSelectedContents] = useRecoilState(
    selectedContentsState
  );
  const [selectedPetStateValue, setSelectedPetState] = useRecoilState(
    selectedPetState
  );
  const setLoading = useSetRecoilState(loadingState);
  const [selectedPetId, setSelectPetId] = useRecoilState(selectedPetIdState);

  // Local states
  const [myPets, setMyPets] = useState([]);
  const [isOpenedMenu, setIsOpenedMenu] = useState(false);
  const [openedMenuPetId, setOpenedMenuPetId] = useState("");
  const [isOpenedLackDbtiPopup, setIsOpenedLackDbtiPopup] = useState(false);

  const menuRef = useRef();

  // Effects
  useEffect(() => {
    if (!selectedContents) {
      setSelectedContents(
        window.localStorage.getItem("last_landing")?.slice(1) ?? "personality"
      );
    }

    document.addEventListener("touchstart", handleMousedownEvent);
    document.addEventListener("mousedown", handleMousedownEvent);

    return () => {
      document.removeEventListener("touchstart", handleMousedownEvent);
      document.removeEventListener("mousedown", handleMousedownEvent);
    };
  }, []);

  useEffect(() => {
    setSelectPetId("");
    if (userAuthState) {
      loadPets();
    }
  }, [userAuthState]);

  // Local functions
  const loadPets = async () => {
    setLoading(true);
    const pets = await retryPromise(async () => {
      const res = await axios.get("https://pppet-ent-server.vercel.app/pets", {
        headers: {
          "Content-Type": "application/json",
          auth: userAuthState,
        },
      });
      return res.data;
    });
    setMyPets(pets);
    setLoading(false);
  };

  const submit = () => {
    if (selectedContents === "personality") {
      navigate("/measure-questions", {
        state: { content: selectedContents, selectMyPet: selectedPet },
      });
    } else if (selectedContents === "relationship") {
      if (!selectedPet.dbti) {
        setIsOpenedLackDbtiPopup(true);
        return;
      }
      navigate("/relationship-dog-owner", {
        state: { content: selectedContents, selectMyPet: selectedPet },
      });
    } else if (selectedContents === "health") {
      navigate("/health-questions", {
        state: { selectMyPet: selectedPet },
      });
    }
  };

  const shiftPersonality = () => {
    navigate("/measure-questions", {
      state: { content: 'personality', selectMyPet: selectedPet },
    });
  };

  const handleMousedownEvent = (e) => {
    if (!menuRef?.current?.contains(e.target)) {
      setIsOpenedMenu(false);
    }
  };

  const updatePet = () => {
    const selectedPet = openedMenuPetId
      ? myPets.find((pet) => pet._id === openedMenuPetId)
      : null;
    setSelectedPetState(selectedPet)
    navigate(`/pet-edit`)
  };

  const deletePet = async () => {
    const selectedPetId = openedMenuPetId;

    setLoading(true);
    await axios.delete(`https://pppet-ent-server.vercel.app/pets?petId=${selectedPetId}`, {
      headers: {
        "Content-Type": "application/json",
        auth: userAuthState,
      },
    });
    setLoading(false);

    setMyPets(myPets.filter((pet) => pet._id !== selectedPetId))

    openToast('펫 삭제가 완료되었습니다.', 'success');
  };

  // Local variables
  const selectedPet = selectedPetId
    ? myPets.find((pet) => pet._id === selectedPetId)
    : null;

  return (
    <div className="w-full h-full relative">
      {/* Basic menu */}
      <div className="w-full h-full px-[20px] py-[24px] box-border">
        <div className="w-full h-full relative">
          <h1 className="font-bold text-[25px] leading-[38px]">
            강아지 프로필을
            <br />
            선택해주세요.
          </h1>
          <div className="mt-[32px] text-[16px] leading-[24px]">
            <b className="font-bold">옆으로 밀어서</b>{" "}
            <span className="font-[500]">새로운 친구도 추가할 수 있어요.</span>
          </div>

          <Button
            onClick={submit}
            enabled={selectedPet}
            additionalClass="absolute bottom-0 mb-[2px]"
          >
            {
              selectedPet
                ? `${selectedPet.name}의 검사 시작하기`
                : "검사 할 펫을 선택해 주세요"
            }
          </Button>
        </div>
      </div>

      {/* Pet list component */}
      <div className="absolute top-[174px] left-0 w-full">
        <div className="relative">
          <Slider
            className="slider variable-width"
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
          >
            {myPets.map((pet) => {
              const petAge = calculateAge(pet.birthday);
              return (
                <div key={pet._id} className="w-full px-[20px]">
                  <div
                    className={`${
                      pet._id === selectedPetId
                        ? "border-[2px] border-solid	border-[#5D80FD]"
                        : "border border-solid	border-[#DDD]"
                    } transition-all duration-100 rounded-[23px] bg-white w-full h-[154px] px-[16px] relative`}
                  >
                    <div className="pt-[19px] text-[20px] leading-[24px]">
                      <b className="font-bold">{pet.name}</b>{" "}
                      <span className="font-[500]">({pet.breed})</span>
                    </div>
                    <div className="mt-[4px] flex text-[14px] font-[500] leading-[22px] text-[#888]">
                      <div>{pet.sex === "M" ? "남아" : "여아"}</div>
                      <div className="mx-[8px] my-[7px] w-[1px] h-[8px] bg-[#DDD]"></div>
                      <div>
                        {petAge.years}살 {petAge.months}개월
                      </div>
                      <div className="mx-[8px] my-[7px] w-[1px] h-[8px] bg-[#DDD]"></div>
                      <div>{pet.weight}kg</div>
                      <div className="mx-[8px] my-[7px] w-[1px] h-[8px] bg-[#DDD]"></div>
                      <div>
                        중성화 수술 {pet.neutering === "Y" ? "완료" : "안함"}
                      </div>
                    </div>
                    <div className="mt-[20px] mx-[8px]">
                      <div
                        className={`rounded-[23px] ${pet.dbti ? "bg-pppet-light-orange" : "bg-[#F4F4F4]"} w-full h-[43px] relative`}
                      >
                        <div className="absolute bottom-[31px] right-[25px]">
                          <img
                            src="/image/born-icon.png"
                            className="w-[38px] h-[27px]"
                          />
                        </div>
                        <div
                          className={`${pet.dbti ? "text-white" : "text-[#ABABAB]"} text-center text-[14px] font-[500] leading-[24px] py-[9px]`}
                        >
                          {pet.dbti
                            ? `${getBbtiAlias(pet.dbti)} #${pet.dbti}`
                            : "아직 댕BTI 검사 결과가 없어요!"}
                        </div>
                      </div>
                    </div>
                    <div className="absolute top-0 left-0 w-full h-full">
                      <button
                        onClick={() => setSelectPetId(pet._id)}
                        className="w-full h-full"
                      />
                    </div>
                    <div className="absolute top-[19px] right-[12px]">
                      <button
                        onClick={() => {
                          setOpenedMenuPetId(pet._id);
                          setIsOpenedMenu(true);
                        }}
                      >
                        <img
                          src="/image/more-icon.png"
                          className="w-[24px] h-[24px]"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="w-full px-[20px]">
              <div className="border border-solid	border-[#DDD] rounded-[23px] bg-white w-full h-[154px]">
                <button
                  onClick={() => navigate("/pet-register")}
                  className="w-full h-full"
                >
                  <div className="w-full h-full">
                    <img
                      src="/image/circle-plus-icon.png"
                      className="w-[50px] h-[50px] mt-[39px] mx-auto"
                    />
                    <div className="mt-[20px] text-center text-[16px] leading-[20px] font-[500]">
                      반려견 프로필 추가하기
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </Slider>

          <Transition
            show={isOpenedMenu}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div ref={menuRef} className="absolute top-0 right-0">
              <div className="absolute w-[84px] h-[100px] top-[19px] right-[59px] rounded-[23px] border border-solid border-[#DDD] bg-white shadow-[0_0px_0px_4px_0px_rgba(0,0,0,0.15)]">
                <button onClick={updatePet} className="w-full">
                  <div className="w-full h-[50px] border-b border-solid border-[#DDD]">
                    <div className="pt-[14px] inline-flex">
                      <img
                        src="/image/pen-icon.png"
                        className="w-[20px] h-[20px]"
                      />{" "}
                      <span className="ml-[4px] text-[14px] font-[500] leading-[22px] text-[#888]">
                        수정
                      </span>
                    </div>
                  </div>
                </button>
                <button onClick={deletePet} className="w-full">
                  <div className="w-full h-[50px]">
                    <div className="pt-[13px] inline-flex">
                      <img
                        src="/image/trash-icon.png"
                        className="w-[20px] h-[20px]"
                      />{" "}
                      <span className="ml-[4px] text-[14px] font-[500] leading-[22px] text-[#FD835D]">
                        삭제
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      {/* If DBTI is not present when relationship */}
      <Transition
        show={isOpenedLackDbtiPopup}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div onClick={() => setIsOpenedLackDbtiPopup(false)} className="fixed top-0 left-0 w-full h-[100dvh] bg-[rgba(0,0,0,0.5)]" />
      </Transition>
      <Transition
        show={isOpenedLackDbtiPopup}
        className="transition-all duration-500 fixed left-0 w-full h-[258px] rounded-[24px_24px_0px_0px] bg-white px-[20px] shadow-[0_0px_0px_4px_0px_rgba(0,0,0,0.08)]"
        enterFrom="opacity-0 bottom-[-100px]"
        enterTo="opacity-100 bottom-0"
        leaveFrom="opacity-100 bottom-0"
        leaveTo="opacity-0 bottom-[-100px]"
      >
        <div className="mt-[52px] text-center text-[16px] leading-[25px] font-[500]">
          {selectedPet?.name}(은)는 성격 유형 분석 검사 결과가<br />
          아직 없어서 궁합 검사를 진행하기 어려워요.
        </div>
        <div className="mt-[12px] text-center text-[20px] leading-[25px] font-[700]">
          지금 바로 성격 검사를 시작해볼까요?
        </div>
        <Button
          onClick={shiftPersonality}
          additionalClass="mt-[30px]"
        >
          검사 시작하기
        </Button>
      </Transition>
    </div>
  );
}
