import styled from "styled-components";

export const RadioButton1 = styled.input.attrs({ type: "radio" })`
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  background-color: white;

  cursor: pointer;

  &:hover {
    color: transparent;
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.9rem;
  }

  &:focus {
    --tw-ring-shadow: none !important;
    --tw-ring-color: none !important;
    box-shadow: none !important;
  }

  &:checked {
    // 이 부분에 원하는 스타일을 작성하세요.
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};

    background-color: white;
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.9rem;
  }

  &:checked:focus {
    // 이 부분에 원하는 스타일을 작성하세요.
    outline: none;
    border-color: #000;
    background-color: transparent;
  }
`;

export const RadioButton2 = styled.input.attrs({ type: "radio" })`
  width: 1.3rem;
  height: 1.3rem;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  background-color: white;

  cursor: pointer;

  &:hover {
    color: transparent;
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.7rem;
  }

  &:focus {
    --tw-ring-shadow: none !important;
    --tw-ring-color: none !important;
    box-shadow: none !important;
  }

  &:checked {
    // 이 부분에 원하는 스타일을 작성하세요.
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};

    background-color: white;
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.7rem;
  }

  &:checked:focus {
    // 이 부분에 원하는 스타일을 작성하세요.
    outline: none;
    border-color: #000000;
    background-color: transparent;
  }
`;

export const RadioButton3 = styled.input.attrs({ type: "radio" })`
  margin-bottom: 10px;
  border: 1px solid #ddd;
  background-color: white;

  cursor: pointer;

  &:hover {
    color: transparent;
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.6rem;
  }

  &:focus {
    --tw-ring-shadow: none !important;
    --tw-ring-color: none !important;
    box-shadow: none !important;
  }

  &:checked {
    // 이 부분에 원하는 스타일을 작성하세요.
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};

    background-color: white;
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.6rem;
  }

  &:checked:focus {
    // 이 부분에 원하는 스타일을 작성하세요.
    outline: none;
    border-color: #000000;
    background-color: transparent;
  }
`;

export const RadioButton4 = styled.input.attrs({ type: "radio" })`
  margin-bottom: 10px;
  border: 1px solid #ddd;
  background-color: white;

  cursor: pointer;

  &:hover {
    color: transparent;
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.6rem;
  }

  &:focus {
    --tw-ring-shadow: none !important;
    --tw-ring-color: none !important;
    box-shadow: none !important;
  }

  &:checked {
    // 이 부분에 원하는 스타일을 작성하세요.
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};

    background-color: white;
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.6rem;
  }

  &:checked:focus {
    // 이 부분에 원하는 스타일을 작성하세요.
    outline: none;
    border-color: #000000;
    background-color: transparent;
  }
`;

export const RadioButton5 = styled.input.attrs({ type: "radio" })`
  width: 1.3rem;
  height: 1.3rem;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  background-color: white;

  cursor: pointer;

  &:hover {
    color: transparent;
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.7rem;
  }

  &:focus {
    --tw-ring-shadow: none !important;
    --tw-ring-color: none !important;
    box-shadow: none !important;
  }

  &:checked {
    // 이 부분에 원하는 스타일을 작성하세요.
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};

    background-color: white;
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.7rem;
  }

  &:checked:focus {
    // 이 부분에 원하는 스타일을 작성하세요.
    outline: none;
    border-color: #000000;
    background-color: transparent;
  }
`;

export const RadioButton6 = styled.input.attrs({ type: "radio" })`
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  background-color: white;

  cursor: pointer;

  &:hover {
    color: transparent;
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.9rem;
  }

  &:focus {
    --tw-ring-shadow: none !important;
    --tw-ring-color: none !important;
    box-shadow: none !important;
  }

  &:checked {
    // 이 부분에 원하는 스타일을 작성하세요.
    border: ${(props) =>
      props.focusQuestion === props.index
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};

    background-color: white;
    background-image: url(${(props) =>
      props.focusQuestion === props.index
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.9rem;
  }

  &:checked:focus {
    // 이 부분에 원하는 스타일을 작성하세요.
    outline: none;
    border-color: #000000;
    background-color: transparent;
  }
`;

export const RadioButton7 = styled.input.attrs({ type: "radio" })`
  width: ${(props) =>
    props.idx === 0
      ? "1.5rem"
      : props.idx === 1
      ? "1.3rem"
      : props.idx === 2
      ? "1rem"
      : props.idx === 3
      ? "1.3rem"
      : "1.5rem"};

  height: ${(props) =>
    props.idx === 0
      ? "1.5rem"
      : props.idx === 1
      ? "1.3rem"
      : props.idx === 2
      ? "1rem"
      : props.idx === 3
      ? "1.3rem"
      : "1.5rem"};

  border: 1px solid #ddd;
  background-color: white;

  cursor: pointer;

  &:hover {
    border: ${(props) =>
      props.focusCount === props.num
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};
    background-image: url(${(props) =>
      props.focusCount === props.num
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${(props) =>
      props.idx === 0
        ? "0.9rem"
        : props.idx === 1
        ? "0.7rem"
        : props.idx === 2
        ? "0.6rem"
        : props.idx === 3
        ? "0.7rem"
        : "0.9rem"};
  }

  &:focus {
    --tw-ring-shadow: none !important;
    --tw-ring-color: none !important;
    box-shadow: none !important;
  }

  &:checked {
    // 이 부분에 원하는 스타일을 작성하세요.
    border: ${(props) =>
      props.focusCount === props.num
        ? "1px solid #5D80FD !important"
        : "1px solid #000 !important"};
    background-color: white;
    background-image: url(${(props) =>
      props.focusCount === props.num
        ? "/image/icon/check_circle_purple.png"
        : "/image/icon/check_circle.png"});
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${(props) =>
      props.idx === 0
        ? "0.9rem"
        : props.idx === 1
        ? "0.7rem"
        : props.idx === 2
        ? "0.6rem"
        : props.idx === 3
        ? "0.7rem"
        : "0.9rem"};
  }

  &:checked:focus {
    // 이 부분에 원하는 스타일을 작성하세요.
    outline: none;
    border-color: #000000;
    background-color: transparent;
  }
`;

export const ProgressBarState = styled.div`
  width: ${(props) => props.calculateProgress}%;
  height: 100%;
  background-color: #5d80fd;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.8s ease-in-out;
`;

export const PercentBarState = styled.div`
  width: ${(props) => props.percent}%;
  height: 100%;
  background-color: ${(props) => props.color};
  border-radius: 23px;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 2s ease-in-out;
`;
