// 반려견 간편 건강 검진

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ProgressBar from "../components/ProgressBar";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  blockHealthTestTransitionState,
  selectedMedicalExaminationState,
  testResultState,
} from "../recoil/atoms/inputState";
import { useNavigate, useLocation } from "react-router-dom";
import { authState } from "../recoil/atoms/authState";
import MedicalExamination5inA from "../components/MedicalExamination5inA";
import MedicalExamination3in1And5in1 from "./MedicalExamination3in1And5in1";
import { initScrollTriggerState, loadingState } from "../recoil/atoms/generalState";

export default function HealthQuestions() {
  const location = useLocation();

  const selectMyPet = location.state?.selectMyPet || "";
  const [medicalExaminationState, setMedicalExaminationState] = useRecoilState(
    selectedMedicalExaminationState
  );
  const setTestResult = useSetRecoilState(testResultState);
  const userAuthState = useRecoilValue(authState);
  const setLoading = useSetRecoilState(loadingState);
  const [initScrollTrigger, setInitScrollTrigger] = useRecoilState(initScrollTriggerState);

  const scrollRef = useRef(null);

  const navigate = useNavigate();

  const [isCheckedList, setIsCheckedList] = useState([]);
  const [isCheckedListA, setIsCheckedListA] = useState([]);
  const [count, setCount] = useState(0);
  const [isCheckList, setIsCheckList] = useState([]);
  const [buttonDisabledFalse, setButtonDisabledFalse] = useState(true);
  const [solvedQuestionNum, setSolvedQuestionNum] = useState(0);
  // 3in1 또는 5in1 문제 focus num
  const [focusCount, setFocusCount] = useState(0);

  const setBlockHealthTestTransition = useSetRecoilState(blockHealthTestTransitionState);

  const {
    data: petMedicalExamination,
  } = useQuery(
    ["medicalExamination"],
    async () => {
      return axios //
        .get("https://pppet-ent-server.vercel.app/questions/health") //
        .then((res) => {
          const composedQuestions = [];
          const questions = res.data.questions;
          for (const partialQuestions of questions) {
            const multipleChoices5List = partialQuestions.filter(
              (question) => question.type === "multiple_choices_5"
            );
            const othersList = partialQuestions.filter(
              (question) => question.type !== "multiple_choices_5"
            );
            composedQuestions.push(...multipleChoices5List);
            composedQuestions.push(othersList);
          }

          const convertQuestion = (question) => {
            const questionTypeMap = {
              multiple_choices_5: "5inA",
              choices_5: "5in1",
              choices_3: "3in1",
            };

            return {
              question: question.question,
              selectAnswerType: questionTypeMap[question.type],
              selectAnswer: question.options,
            };
          };

          const convertedQuestions = [];
          for (const partialQuestions of composedQuestions) {
            if (Array.isArray(partialQuestions)) {
              convertedQuestions.push(
                partialQuestions.map((question) => convertQuestion(question))
              );
            } else {
              convertedQuestions.push(convertQuestion(partialQuestions));
            }
          }

          return convertedQuestions;
        });
    },
    { staleTime: 1000 * 6 }
  );

  let idx = 0;

  function addIndices(array) {
    for (let i = 0; i < array?.length; i++) {
      if (Array.isArray(array[i])) {
        addIndices(array[i]);
      } else {
        array[i].index = idx++;
      }
    }
  }

  addIndices(petMedicalExamination);

  // 총 문제 갯수를 구하는 함수 입니다.
  function flattenArray(arr) {
    let count = 0;

    function countElements(arr) {
      arr?.forEach((element) => {
        if (Array.isArray(element)) {
          countElements(element);
        } else {
          count++;
        }
      });
    }

    countElements(arr);

    return count;
  }

  const allQuestionNum = flattenArray(petMedicalExamination);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (data) => {
      setLoading(true);
      const res = await axios.post(
        `https://pppet-ent-server.vercel.app/questions/health`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            auth: userAuthState,
          },
        }
      );
      const result = res.data;
      setLoading(false);
      setTestResult([result]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["medicalExamination", userAuthState]);
      },
    }
  );

  useEffect(() => {
    setMedicalExaminationState([]);
    setIsCheckedList([]);
    setIsCheckedListA([]);
    setTestResult([]);
    for (let i = 0; i < petMedicalExamination?.length; i++) {
      localStorage.setItem(`checkList${i}`, JSON.stringify([]));
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      for (let i = 0; i < petMedicalExamination?.length; i++) {
        localStorage.setItem(`checkList${i}`, JSON.stringify([]));
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [petMedicalExamination]);

  window.addEventListener("popstate", function (event) {
    // 페이지를 떠나는 경우, 로컬 스토리지의 데이터를 초기화 합니다.
    for (let i = 0; i < petMedicalExamination?.length; i++) {
      localStorage.setItem(`checkList${i}`, JSON.stringify([]));
    }

    setSolvedQuestionNum(0);
  });

  useEffect(() => {
    if (scrollRef.current) {
      scrollTopSmooth();
    }

    setInitScrollTrigger(false);
  }, [initScrollTrigger]);

  useEffect(() => {
    setBlockHealthTestTransition(true);
    setTimeout(() => {
      setInitScrollTrigger(true);
      setBlockHealthTestTransition(false);
    }, 100);
    const checkData = localStorage.getItem(`checkList${count}`);
    if (checkData && checkData !== null) {
      setIsCheckList(JSON.parse(checkData));
    } else {
      setIsCheckList([]);
    }
  }, [count]);

  useEffect(() => {
    localStorage.setItem(`checkList${count}`, JSON.stringify(isCheckList));
  }, [isCheckList, count]);

  const scrollTopSmooth = () => {
    scrollRef.current.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const isCompletePage = petMedicalExamination
    && (
      (petMedicalExamination[count]?.selectAnswerType === "5inA" && !buttonDisabledFalse) ||
      (Array.isArray(petMedicalExamination[count]) && !buttonDisabledFalse)
    );

  return (
    <div className="w-full h-full pt-[96px] flex flex-col justify-between">
      <div>
        <div className="w-full max-w-[720px] px-[20px] pt-[13px] bg-white fixed top-0 left-[50%] transform -translate-x-1/2 z-[9999]">
          <div className="w-full border-b-[1px] border-solid border-[#ddd]">
            <div className="flex items-center justify-between">
              <button>
                <img
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);

                    for (let i = 0; i < petMedicalExamination?.length; i++) {
                      localStorage.setItem(`checkList${i}`, JSON.stringify([]));
                    }
                  }}
                  src={process.env.PUBLIC_URL + `/image/icon/back.png`}
                  className="w-[24px] h-[24px]"
                  alt="back"
                />
              </button>

              <h1 className="text-[24px] font-[700] leading-[32px]">
                스마트 건강 검진
              </h1>

              <div className="flex items-center text-[16px] font-[300]">
                <p className="font-[500]">{solvedQuestionNum}</p>
                <p>/</p>
                <p>{allQuestionNum}</p>
              </div>
            </div>
            <ProgressBar
              petMedicalExamination={allQuestionNum}
              count={solvedQuestionNum}
            />
          </div>
        </div>

        <div
          ref={scrollRef}
          className={`${
            petMedicalExamination &&
            petMedicalExamination[count]?.selectAnswerType === "5inA"
              ? "px-[20px] box-border"
              : ""
          } w-full overflow-scroll scrollbar-hide`}
        >
          {petMedicalExamination && (
            <>
              {petMedicalExamination[count]?.selectAnswerType === "5inA" ? (
                <div className="w-full mt-[24px]">
                  <p className="mb-[24px] text-[#1E274D] text-[16px] font-[700] leading-[25px]">
                    {petMedicalExamination[count].question}
                  </p>

                  <MedicalExamination5inA
                    index={count + 1}
                    datas={petMedicalExamination[count].selectAnswer}
                    isCheckedList={isCheckedList}
                    setIsCheckedList={setIsCheckedList}
                    totalIndex={petMedicalExamination[count].index}
                    petMedicalExamination={petMedicalExamination}
                    count={count}
                    isCheckList={isCheckList}
                    setIsCheckList={setIsCheckList}
                    setButtonDisabledFalse={setButtonDisabledFalse}
                    setSolvedQuestionNum={setSolvedQuestionNum}
                  />
                </div>
              ) : (
                <div>
                  {petMedicalExamination[count]?.map((test, num) => {
                    return (
                      <MedicalExamination3in1And5in1
                        petMedicalExamination={petMedicalExamination}
                        test={test}
                        num={num}
                        count={count}
                        isCheckedListA={isCheckedListA}
                        setIsCheckedListA={setIsCheckedListA}
                        isCheckList={isCheckList}
                        setIsCheckList={setIsCheckList}
                        buttonDisabledFalse={buttonDisabledFalse}
                        setButtonDisabledFalse={setButtonDisabledFalse}
                        setSolvedQuestionNum={setSolvedQuestionNum}
                        focusCount={focusCount}
                        setFocusCount={setFocusCount}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}

          <div className={`${
            petMedicalExamination && petMedicalExamination[count]?.selectAnswerType === "5inA" ? "mt-[80px]" : "mt-[24px] px-[20px]"
          } w-full h-[55px] mb-[24px] box-border flex items-center gap-x-2`}>
            <button
              className={
                count === 0
                  ? "w-1/3 h-full rounded-[23px] bg-[#ddd] border-[1px] border-solid border-[#ccc] text-[#ABABAB]"
                  : "w-1/3 h-full rounded-[23px] bg-[#1E274D] text-white"
              }
              disabled={count === 0 ? true : false}
              onClick={(e) => {
                e.preventDefault();

                setCount((prev) => prev - 1);
              }}
            >
              이전
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();

                if (count + 1 !== petMedicalExamination?.length) {
                  // 마지막 문제가 아닐 때
                  if (
                    petMedicalExamination[count].selectAnswerType === "5inA"
                  ) {
                    setMedicalExaminationState((prev) => [
                      ...prev,
                      isCheckedList,
                    ]);
                    setIsCheckedList([]);
                  } else if (
                    Array.isArray(petMedicalExamination[count]) &&
                    petMedicalExamination[count].length > 0 &&
                    isCheckedListA?.filter((v) => v != null).length ===
                      petMedicalExamination[count].length
                  ) {
                    setMedicalExaminationState((prev) => [
                      ...prev,
                      ...isCheckedListA,
                    ]);
                    setIsCheckedListA([]);
                  }

                  setCount((prev) => prev + 1);
                } else if (count + 1 === petMedicalExamination?.length) {
                  // 마지막 문제일 때
                  const questionsIsArrayList = petMedicalExamination.map((v) => Array.isArray(v));
                  const answersList = questionsIsArrayList.map((v, i) => JSON.parse(localStorage.getItem(`checkList${i}`)));
                  const finalAnswers = [];
                  answersList.forEach((v, i) => {
                    if (questionsIsArrayList[i]) {
                      finalAnswers.push(...v.map((k) => [k]))
                    } else {
                      finalAnswers.push(v)
                    }
                  });
                  const shiftedAnswers = finalAnswers.map((list) =>
                    list.map((v) => v + 1)
                  );

                  // medicalExaminationState 데이터 서버로 보내주기
                  mutation.mutate({
                    petId: selectMyPet._id,
                    answers: shiftedAnswers,
                  });

                  setCount(0);
                  setMedicalExaminationState([]);
                  setIsCheckedList([]);
                  setIsCheckedListA([]);
                  setTestResult([]);
                  for (let i = 0; i < petMedicalExamination?.length; i++) {
                    localStorage.setItem(`checkList${i}`, JSON.stringify([]));
                  }

                  navigate(`/health-result?petId=${selectMyPet._id}`, { state: { selectMyPet } });
                }

                setIsCheckedList([]);
                setIsCheckedListA([]);
              }}
              className={`${
                isCompletePage
                  ? "bg-[#1E274D]"
                  : "bg-[#ddd]"
              } w-2/3 h-full box-border rounded-[23px] border-[1px] border-solid border-[#ccc] transition-all duration-700`}
              disabled={
                isCompletePage
                  ? false
                  : true
              }
            >
              <p
                className={`${
                  isCompletePage
                    ? "text-white"
                    : "text-[#ABABAB]"
                }`}
              >
                {isCompletePage && count !== petMedicalExamination?.length - 1
                  ? "다음으로"
                  : isCompletePage && count === petMedicalExamination?.length - 1
                  ? "결과 확인하기"
                  : "모든 질문에 답해주세요"}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
