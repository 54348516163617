import React from "react";

export default function Button({
  path,
  buttonText,
  onClick,
  petData,
  selectPet,
  inputName,
  inputType,
  inputBirthYear,
  inputBirthMonth,
  inputBirthDay,
  inputWeight,
  inputSelectSex,
  inputSelectNeutralization,
  inputBreedType,
  inputOwnerName,
  inputOwnerBirthYear,
  inputOwnerBirthMonth,
  inputOwnerBirthDay,
  inputOwnerSex,
  inputOwnerRole,
  inputOwnerMbti,
  modalOpen,
}) {
  const condition =
    (path === "/pet-register" || path === "/pet-edit" ) &&
    inputName.length !== 0 &&
    inputType.length !== 0 &&
    inputBirthYear.length !== 0 &&
    inputBirthMonth.length !== 0 &&
    inputWeight.length !== 0 &&
    inputSelectSex.length !== 0 &&
    inputSelectNeutralization.length !== 0 &&
    inputBreedType.length !== 0;

  const condition2 =
    path === "/relationship-dog-owner" &&
    inputOwnerName.length !== 0 &&
    inputOwnerBirthYear.length !== 0 &&
    inputOwnerBirthMonth.length !== 0 &&
    inputOwnerBirthDay.length !== 0 &&
    inputOwnerSex.length !== 0 &&
    inputOwnerRole.length !== 0 &&
    inputOwnerMbti.length !== 0;

  return (
    <button
      disabled={
        path === "/personality" ||
        path === "/relationship" ||
        path === "/health" ||
        path === "/" ||
        path === "/personality-result" ||
        path === "/relationship-result" ||
        path === "/health-result" ||
        (path === "/pet-selection" && petData && selectPet) ||
        condition
          ? false
          : (path === "/pet-selection" && (!petData || !selectPet)) ||
            ((path === "/pet-register" || path === "/pet-edit") && !condition)
          ? true
          : path === "/relationship-dog-owner" && condition2
          ? false
          : path === "/result"
          ? false
          : true
      }
      onClick={onClick}
      type={(path === "/pet-register" || path === "/pet-edit") ? "submit" : "button"}
      className={`${
        path === "/personality" ||
        path === "/relationship" ||
        path === "/health" ||
        path === "/" ||
        path === "/personality-result" ||
        path === "/relationship-result" ||
        path === "/health-result" ||
        (path === "/pet-selection" && petData && selectPet) ||
        condition
          ? "bg-[#1E274D] text-white"
          : (path === "/pet-selection" && (!petData || !selectPet)) ||
            ((path === "/pet-register" || path === "/pet-edit") && !condition)
          ? "bg-[#dddddd] text-[#ababab] border border-solid border-[#ccc]"
          : path === "/relationship-dog-owner" && condition2
          ? "bg-[#1E274D] text-white"
          : path === "/result" && !modalOpen
          ? "bg-[#1E274D] text-white"
          : path === "/result" && modalOpen
          ? "mt-[13px] bg-[#1E274D] text-white"
          : "bg-[#dddddd] text-[#ababab] border border-solid border-[#ccc]"
      } rounded-[23px] text-[16px] leading-[24px] font-[500] w-full py-[15.5px] flex justify-center items-center gap-x-2 ${
        (path === "/pet-register" || path === "/pet-edit" || path === "/relationship-dog-owner") ? 'mt-[26px]' : 'mt-[60px]'
      }`}
    >
      {buttonText}
    </button>
  );
}
