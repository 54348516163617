import React, { useEffect } from "react";
import { RadioButton7 } from "./StyledComponents";
import { useRecoilValue } from "recoil";
import { blockHealthTestTransitionState } from "../recoil/atoms/inputState";

export default function MedicalExamination3in1({
  index,
  num,
  idx,
  data,
  isCheckedListA,
  setIsCheckedListA,
  count,
  petMedicalExamination,
  isCheckList,
  setIsCheckList,
  setButtonDisabledFalse,
  setSolvedQuestionNum,
  focusCount,
  setFocusCount,
  divRefs2,
}) {
  const blockHealthTestTransition = useRecoilValue(blockHealthTestTransitionState);

  const handleChange = (e, idx, numValue) => {
    // idx = 선택지의 index. 0부터 시작.
    // numValue = 현재 페이지에서 이게 몇번째 문제인지. 0부터 시작.

    const newIsCheckedListA = [...isCheckedListA];
    newIsCheckedListA[numValue] = idx;
    setIsCheckedListA(newIsCheckedListA);

    const newIsCheckList = [...isCheckList];
    newIsCheckList[numValue] = idx;
    setIsCheckList(newIsCheckList);

    if (isCheckList[numValue] === undefined) {
      setSolvedQuestionNum((prev) => prev + 1);
    }

    setFocusCount(num + 1);

    divRefs2.current[focusCount]?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setFocusCount(0);
  }, [setFocusCount]);

  useEffect(() => {
    if (
      petMedicalExamination[count][num]?.selectAnswerType === "3in1" ||
      petMedicalExamination[count][num]?.selectAnswerType === "5in1"
    ) {
      if (
        Array.isArray(isCheckList) &&
        isCheckList.filter((v) => v != null).length === petMedicalExamination[count]?.length
      ) {
        setButtonDisabledFalse(false);
      } else {
        setButtonDisabledFalse(true);
      }
    }
  }, [setButtonDisabledFalse, isCheckList, count, petMedicalExamination, num]);

  return (
    <>
      {Array.isArray(petMedicalExamination[count]) &&
      petMedicalExamination[count][num]?.selectAnswerType === "3in1" ? (
        <div className="w-full">
          <input
            autocomplete="off"
            className="sr-only"
            type="checkbox"
            id={`health-${num}-${idx}`}
            name={`health-${index + num}`}
            value={`option${idx}`}
            onChange={(e) => handleChange(e, idx, num)}
            checked={isCheckList && isCheckList[num] === idx ? true : false}
          />

          <label
            className={`${
              isCheckList && isCheckList[num] === idx ? "bg-[#5D80FD]" : "border border-solid border-[#ddd]"
            } ${
              blockHealthTestTransition ? '' : 'transition-all duration-700'
            } w-full h-[50px] px-[15px] box-border mb-4 cursor-pointer text-[#888] text-center rounded-[23px] flex items-center justify-center`}
            htmlFor={`health-${num}-${idx}`}
          >
            <span
              className={`${
                isCheckList && isCheckList[num] === idx
                  ? "text-white"
                  : "text-[#888]"
              } ${
                blockHealthTestTransition ? '' : 'transition-all duration-700'
              } text-[14px] font-[500] leading-[20px]`}
              dangerouslySetInnerHTML={{ __html: ((data) => {
                if (data.length <= 5) {
                  return data;
                }
                if (data.startsWith('한 달에') || data.startsWith('다섯 달에')) {
                  return data.split(' ').map((v, i) => i == 1 ? `${v}<br/>`: v).join(' ')
                }
                return data.split(' ').map((v, i) => i == 0 ? `${v}<br/>`: v).join(' ')
              })(data) }}
            />
          </label>
        </div>
      ) : (
        <div>
          <label className="h-[24px]">
            <RadioButton7
              focusCount={focusCount}
              num={num}
              idx={idx}
              type="radio"
              name={`health-${index + num}`}
              value={`option${idx}`}
              onChange={(e) => handleChange(e, idx, num)}
              checked={isCheckList && isCheckList[num] === idx ? true : false}
            ></RadioButton7>
          </label>
          <p
            className="mt-[8px] text-[11px] font-[400] leading-[20px] tracking-[-0.44px] text-center"
            dangerouslySetInnerHTML={{ __html: ((data) => {
              if (data.length <= 5) {
                return data;
              }
              if (
                data.startsWith('한 달에')
                || data.startsWith('최근 3개월')
                || data.startsWith('입 근처')
                || data.startsWith('코 근처')
                || data.startsWith('눈 앞머리')
                || data.startsWith('눈 주변에만')
              ) {
                return data.split(' ').map((v, i) => i == 1 ? `${v}<br/>`: v).join(' ')
              }
              return data.split(' ').map((v, i) => i == 0 ? `${v}<br/>`: v).join(' ')
            })(data) }}
          />
        </div>
      )}
    </>
  );
}
