import React from "react";

export default function Button({
  children,
  enabled = true,
  onClick,
  type = 'button',
  additionalClass = '',
}) {
  return (
    <button
      disabled={!enabled}
      onClick={onClick}
      type={type}
      className={
        (enabled ? 'bg-pppet-dark-blue text-white' : 'bg-[#dddddd] text-[#ababab] border border-solid border-[#CCC]')
        + ' text-[16px] font-[500] leading-[24px] rounded-[23px] w-full py-[15.5px] text-center'
        + ` ${additionalClass}`
      }
    >
      {children}
    </button>
  );
}
