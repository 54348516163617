import React, { useEffect, useState } from "react";
import PercentBar from "../components/PercentBar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../components-v2/Button";
import { useRecoilState, useSetRecoilState } from "recoil";
import { testResultState } from "../recoil/atoms/inputState";
import { loadingState } from "../recoil/atoms/generalState";
import axios from "axios";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Slider from "react-slick";

export default function RelationshipResult() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [existsResultPet, setExistsResultPet] = useState(null);
  const [existsOwnerName, setExistsOwnerName] = useState('');

  const location = useLocation();
  const selectMyPet = location.state?.selectMyPet || existsResultPet;
  const ownerName = location.state?.ownerName || existsOwnerName;

  const [testResult, setTestResult] = useRecoilState(testResultState);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    const qsPetId = searchParams.get('petId');
    if (qsPetId) {
      loadExistsResult(qsPetId);
    }
  }, []);

  const loadExistsResult = async (qsPetId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://pppet-ent-server.vercel.app/exists-result/${qsPetId}/relationship`);
      const data = response.data;
      setTestResult([data.result]);
      setExistsResultPet(data.pet);
      setExistsOwnerName(data.ownerName);
    } catch (error) {
      console.info('Perhaps a timing issue may be causing the error if the scan has just finished.');
    }
    setLoading(false);
  };

  const handleShareButtonClick = (e) => {
    window.Kakao.Share.sendDefault({
      objectType: 'text',
      text:
        '[펫펫펫 반려가족 궁합 검사]\n우리 아이와 나의 궁합을 확인해보세요!',
      link: {
        mobileWebUrl: `https://ent.pppet.shop/relationship-result?petId=${selectMyPet._id}`,
        webUrl: `https://ent.pppet.shop/relationship-result?petId=${selectMyPet._id}`,
      },
    });
  };

  const CircularProgressbarValue = testResult[0]?.totalScore ?? 0;

  const getPercentLevel = (value) => {
    if (value >= 80) {
      return '높음';
    } else if (value >= 45) {
      return '중간';
    } else {
      return '낮음';
    }
  };

  return (
    <div>
      <div className="px-[20px] bg-[#FAFAFA]">
        <h1 className="pt-[25px] text-[25px] font-[700] leading-[38px] text-[#1E274D]">
          {ownerName}님과 {selectMyPet?.name}의<br/>궁합 결과를 분석했어요!
        </h1>

        <div className="mt-[50px] mx-auto relative w-[206px] h-[206px]">
          <CircularProgressbar
            value={CircularProgressbarValue}
            strokeWidth={12}
            styles={buildStyles({
              pathTransitionDuration: 3,
              rotation: -0.01,
              trailColor: '#F8F8F8',
              pathColor: '#5D80FD',
              backgroundColor: '#5D80FD',
            })}
          />
          <div className="absolute top-0 left-0 w-[206px] h-[206px] text-center">
            <div className="pt-[50px] text-[16px] text-[#1E274D] font-[500] leading-[22px]">
              관계도
            </div>
            <div className="pt-[8.5px] text-[40px] text-[#5D80FD] font-[700] leading-[32px]">
              {CircularProgressbarValue}%
            </div>
            <div className="pt-[16px] inline-flex">
              <div className="mx-[auto] rounded-[23px] bg-[#5D80FD] text-white text-[16px] font-[500] leading-[22px] py-[2px] px-[8px]">
                {getPercentLevel(CircularProgressbarValue)}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[80px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[48px] pb-[10px] px-[20px] relative">
          <div className="absolute top-[-33px] left-0">
            <img src="/image/relationship-result-message-1.png" className="w-[214px] h-[51px]" />
          </div>
          {/* Title */}
          <div className="text-[20px] font-[700] leading-[28px] text-[#1E274D]">
            {testResult[0]?.mbti.title}
          </div>
          {/* Description */}
          <div
            className="mt-[34px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.mbti.pros.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="mt-[80px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[48px] pb-[10px] px-[20px] relative">
          <div className="absolute top-[-33px] left-0">
            <img src="/image/relationship-result-message-2.png" className="w-[201px] h-[51px]" />
          </div>
          {/* Title */}
          <div className="text-[20px] font-[700] leading-[28px] text-[#1E274D]">
            더욱 돈독해지기 위한 꿀팁!
          </div>
          {/* Description */}
          <div
            className="mt-[34px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.mbti.cons.split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="mt-[50px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[26px] pb-[10px] px-[20px] relative">
          <div className="flex text-[#5D80FD] text-[20px] font-[700] leading-[26px] relative">
            <div className="text-[#1E274D]">
              상대방에 대한 이해
            </div>
            <div className="ml-[10px] text-[16px] font-[500] leading-[26px] text-white px-[7px] bg-[#5D80FD] rounded-[23px]">
              {getPercentLevel(testResult[0]?.elementGauges[0] ?? 0)}
            </div>
            <div className="absolute top-0 right-0">
              {testResult[0]?.elementGauges[0] ?? 0}%
            </div>
          </div>
          <div className="mt-[15px]">
            <PercentBar percent={testResult[0]?.elementGauges[0] ?? 0} />
          </div>
          {/* Title */}
          <div className="mt-[30px] text-[20px] font-[700] leading-[28px]">
            {testResult[0]?.elementComments[1]}
          </div>
          {/* Description */}
          <div
            className="mt-[25px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.contents[1].split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="mt-[20px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[26px] pb-[10px] px-[20px] relative">
          <div className="flex text-[#FDDC00] text-[20px] font-[700] leading-[26px] relative">
            <div className="text-[#1E274D]">
              서로에 대한 믿음
            </div>
            <div className="ml-[10px] text-[16px] font-[500] leading-[26px] text-white px-[7px] bg-[#FDDC00] rounded-[23px]">
              {getPercentLevel(testResult[0]?.elementGauges[1] ?? 0)}
            </div>
            <div className="absolute top-0 right-0">
              {testResult[0]?.elementGauges[1] ?? 0}%
            </div>
          </div>
          <div className="mt-[15px]">
            <PercentBar color="#FDDC00" percent={testResult[0]?.elementGauges[1] ?? 0} />
          </div>
          {/* Title */}
          <div className="mt-[30px] text-[20px] font-[700] leading-[28px]">
            {testResult[0]?.elementComments[2]}
          </div>
          {/* Description */}
          <div
            className="mt-[25px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.contents[2].split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="pt-[85px] text-[#1E274D] text-[20px] font-[700] leading-[28px]">
          최종 결과 발표!<br/>{ownerName}님과 {selectMyPet?.name}의 <span className="bg-[#E2F6FF] rounded-[11px] px-[3px]">궁합결과</span>는
        </div>

        <div className="mt-[24px] w-full border border-solid border-[#DDD] rounded-[23px] bg-white pt-[40px] pb-[10px] px-[20px] relative">
          {/* Title */}
          <div className="text-[22px] font-[700] leading-[28px] text-[#5D80FD]">
            {testResult[0]?.elementComments[0]}
          </div>
          {/* Description */}
          <div
            className="mt-[34px] text-[#515151] text-[16px] font-[500] leading-[28px]"
            dangerouslySetInnerHTML={{
              __html: testResult[0]?.contents[0].split('.').join('.<br/><br/>').split('!').join('!<br/><br/>')
            }}
          />
        </div>

        <div className="mt-[20px]">
          {
            location.state?.selectMyPet?._id !== existsResultPet?._id ? (
              <Button
                onClick={() => navigate('/relationship')}
              >
                다시 해보기
              </Button>
            ) : (
              <Button
                onClick={handleShareButtonClick}
                additionalClass="flex justify-center gap-x-[8px]"
              >
                <img src="/image/kakaotalk-icon.png" className="w-[24px] h-[24px]" />
                <span>
                  결과 공유하기
                </span>
              </Button>
            )
          }
        </div>
      </div>

      <div className="bg-[#FAFAFA]">
        <div className="w-full pt-[30px] px-[20px]">
          <div className="w-full flex items-center text-[1rem]">
            <img
              src={process.env.PUBLIC_URL + "./image/icon/dog.png"}
              alt="dog"
            />
            <p className="ml-3 font-semibold">이런 검사는 어떠세요?</p>
          </div>
        </div>
        <div className="py-[12px]">
          <Slider className="slider variable-width" dots={false} infinite={true} speed={500} slidesToShow={1} slidesToScroll={1} variableWidth={true} arrows={false}>
            <div style={{ width: 330 }}>
              <a href="/health">
                <img className="ml-4" src="/image/recommendation-boxs/health.png" width={316} />
              </a>
            </div>
            <div style={{ width: 330 }}>
              <a href="/personality">
                <img className="ml-4" src="/image/recommendation-boxs/personality.png" width={316} />
              </a>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
