import React, { useEffect, useState } from "react";
import { PercentBarState } from "./StyledComponents";

export default function PercentBar({ percent, color = '#5D80FD' }) {
  const [currentPercent, setCurrentPercent] = useState(0);

  useEffect(() => {
    setCurrentPercent(percent);
  }, [percent]);

  return (
    <div className="w-full h-[28px] bg-[#eee] rounded-full relative">
      <PercentBarState percent={currentPercent} color={color} />
    </div>
  );
}
