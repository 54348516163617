import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  healthDetailResultModalOpenState,
  inputPetBirthDayState,
  inputPetBirthMonthState,
  inputPetBirthYearState,
  inputPetNameState,
  inputPetSelectNeutralization,
  inputPetSelectSex,
  inputPetTypeState,
  inputPetWeightState,
  selectedMedicalExaminationState,
  selectedTabState,
  selectedTestState,
} from "../recoil/atoms/inputState";

export default function Header({ setSideBarDisplay }) {
  const location = useLocation();
  const navigate = useNavigate();
  const setSelectedTab = useSetRecoilState(selectedTabState);
  const setInputName = useSetRecoilState(inputPetNameState);
  const setInputType = useSetRecoilState(inputPetTypeState);
  const setInputBirthYear = useSetRecoilState(inputPetBirthYearState);
  const setInputBirthMonth = useSetRecoilState(inputPetBirthMonthState);
  const setInputBirthDay = useSetRecoilState(inputPetBirthDayState);
  const setInputWeight = useSetRecoilState(inputPetWeightState);
  const setInputSelectSex = useSetRecoilState(inputPetSelectSex);
  const setTestState = useSetRecoilState(selectedTestState);
  const setInputSelectNeutralization = useSetRecoilState(
    inputPetSelectNeutralization
  );
  const setMedicalExaminationState = useSetRecoilState(
    selectedMedicalExaminationState
  );
  const detailModalOpen = useRecoilValue(healthDetailResultModalOpenState);

  const back = (e) => {
    e.preventDefault();
    navigate(-1);
    setSelectedTab(0);
    setTestState([]);
    setMedicalExaminationState([]);
    setInputName("");
    setInputType("");
    setInputBirthYear("");
    setInputBirthMonth("");
    setInputWeight("");
    setInputBirthDay("");
    setInputSelectSex("");
    setInputSelectNeutralization("");
  };

  return (
    <>
      {
      location.pathname.includes("-result") ? (
        <div className="cursor-pointer w-full px-[20px] py-[10px] box-border flex justify-between items-center bg-[#FAFAFA]">
          <img
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
              setTestState([]);
              setMedicalExaminationState([]);
            }}
            className="h-[16px]"
            src={process.env.PUBLIC_URL + `/image/logo.png`}
            alt="logo_img"
          />

          <button onClick={() => setSideBarDisplay(true)}>
            <img
              src={process.env.PUBLIC_URL + "./image/icon/menu.png"}
              alt="menu"
            />
          </button>
        </div>
      ) : detailModalOpen ? (
        <></>
      ) : location.pathname === "/measure-questions" ||
        location.pathname === "/health-questions" ? (
        <></>
      ) : ["/pet-selection", "/pet-register", "/pet-edit", "/relationship-dog-owner"].includes(location.pathname) ? (
        <div className="cursor-pointer w-full px-[20px] py-[10px] box-border flex justify-between items-center">
          <button>
            <img
              onClick={back}
              src={process.env.PUBLIC_URL + `/image/icon/back.png`}
              className="w-[24px] h-[24px]"
              alt="back"
            />
          </button>

          <img
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
              setTestState([]);
              setMedicalExaminationState([]);
            }}
            className="h-[16px]"
            src={process.env.PUBLIC_URL + `/image/logo.png`}
            alt="logo_img"
          />

          <button onClick={() => setSideBarDisplay(true)}>
            <img
              src={process.env.PUBLIC_URL + "./image/icon/menu.png"}
              alt="menu"
            />
          </button>
        </div>
      ) : (
        // Default header
        <div className="cursor-pointer w-full px-[20px] py-[10px] box-border flex justify-between items-center">
          <img
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
              setTestState([]);
              setMedicalExaminationState([]);
            }}
            className="h-[16px]"
            src={process.env.PUBLIC_URL + `/image/logo.png`}
            alt="logo_img"
          />

          <button onClick={() => setSideBarDisplay(true)}>
            <img
              src={process.env.PUBLIC_URL + "./image/icon/menu.png"}
              alt="menu"
            />
          </button>
        </div>
      )}
    </>
  );
}
