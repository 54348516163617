export const sleep = (t) => new Promise((r) => setTimeout(r, t));

export const retryPromise = async (func, retryCount = 5) => {
  let tryCount = 0;
  let lastError = null;
  while (retryCount > tryCount) {
    try {
      const result = await func();
      return result;
    } catch (error) {
      lastError = error;
    }
    tryCount++;
  }
  throw lastError;
}

// birthDate: YYYY.MM.DD
export const calculateAge = (birthDate) => {
  if (birthDate == null) {
    return {
      years: 0,
      months: 0,
    };
  }

  var today = new Date();
  const [bYear, bMonth, bDate] = birthDate.split('.');
  var birth = new Date(bYear, Number(bMonth) - 1, bDate, 0, 0, 0);

  var years = today.getFullYear() - birth.getFullYear();
  var months = today.getMonth() - birth.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }

  return {
    years: years,
    months: months,
  };
}
