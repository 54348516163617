import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components-v2/Button";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authState } from "../recoil/atoms/authState";
import { selectedContentsState } from "../recoil/atoms/generalState";

export default function PersonalityLanding() {
  const navigate = useNavigate();

  const [auth] = useRecoilState(authState);
  const setSelectedContents = useSetRecoilState(selectedContentsState);

  useState(() => {
    window.localStorage.setItem('last_landing', '/personality');
    setSelectedContents('personality');
  }, []);

  const handleStartButtonClick = (e) => {
    if (auth) {
      navigate("/pet-selection");
    } else {
      window.location.href = "https://pppet.shop/auth-ent.html";
    }
  };

  return (
    <div className="w-full px-[20px] py-[24px] box-border">
      <div className="w-full">
        <h1 className="text-[25px] leading-[45px] font-[700]">
          댕BTI 검사
        </h1>

        <div className="mt-[32px] text-[16px] font-[500] leading-[25px]">
          <b>우리 강아지는</b> 어떤 매력이 숨어있을까요?<br />
          <br />
          <b>빅데이터</b>를 통해 분석한<br />
          우리 댕댕이의 성격 지금 바로 발견해보세요!
        </div>

        <div className="mt-[27px] inline-flex gap-x-[4px] px-[11px] py-[6px] bg-[#F3F4F6] font-[500] text-[14px] leading-[16px] rounded-[23px]">
          <img
            src={process.env.PUBLIC_URL + `/image/timer-icon.png`}
            className="w-[16px] h-[16px]"
          />
          <div>
            소요시간 <b>8분</b>
          </div>
        </div>

        <div className="mt-[30px]">
          <img src="/image/personality-landing-symbol.png" className="w-[203px] h-[208px] mx-auto" />
        </div>
      </div>

      <Button
        onClick={handleStartButtonClick}
        additionalClass="mt-[62px] flex justify-center gap-x-[8px]"
      >
        {
          auth
            ? "지금 바로 테스트 시작하기"
            : (
              <>
                <img src="/image/kakaotalk-icon.png" className="w-[24px] h-[24px]" />
                <span>
                  1초만에 로그인하고 시작하기
                </span>
              </>
            )
        }
        
      </Button>
    </div>
  );
}
