import React, { useEffect, useRef, useState } from "react";
import MedicalExamination3in1 from "../components/MedicalExamination3in1";

export default function MedicalExamination3in1And5in1({
  petMedicalExamination,
  test,
  num,
  count,
  isCheckedListA,
  setIsCheckedListA,
  isCheckList,
  setIsCheckList,
  setButtonDisabledFalse,
  setSolvedQuestionNum,
  focusCount,
  setFocusCount,
}) {
  const divRefs2 = useRef([]);
  useEffect(() => {
    divRefs2.current = Array.from(
      { length: petMedicalExamination[count]?.length },
      () => React.createRef()
    );
  }, [petMedicalExamination, count]);

  return (
    <div
      key={num}
      className={`${
        num !== petMedicalExamination[count]?.length - 1
          ? "border-b-[2px] border-solid border-[#ddd]"
          : ""
      } py-[45px] relative px-[20px] box-border`}
    >
      <div
        ref={divRefs2.current[num]}
        className={`${
          num !== focusCount
            ? "w-full h-full opacity-100"
            : "w-full h-full opacity-0"
        } bg-[rgba(255,255,255,0.7)] absolute top-0 left-0 transition-all duration-700 pointer-events-none`}
      />
      <p className="mb-[16px] text-[#1E274D] text-[16px] font-[700] leading-[25px]">{test.question}</p>

      <div
        className={`${
          test?.selectAnswerType === "3in1"
            ? "grid grid-cols-3 gap-x-[15px]"
            : "grid grid-cols-5 text-center"
        }`}
      >
        {test.selectAnswer?.map((answer, idx) => {
          return (
            <MedicalExamination3in1
              key={idx}
              index={count + 1}
              num={num}
              idx={idx}
              data={answer}
              isCheckedListA={isCheckedListA}
              setIsCheckedListA={setIsCheckedListA}
              count={count}
              petMedicalExamination={petMedicalExamination}
              totalIndex={test.index}
              isCheckList={isCheckList}
              setIsCheckList={setIsCheckList}
              setButtonDisabledFalse={setButtonDisabledFalse}
              setSolvedQuestionNum={setSolvedQuestionNum}
              focusCount={focusCount}
              setFocusCount={setFocusCount}
              divRefs2={divRefs2}
            />
          );
        })}
      </div>
    </div>
  );
}
