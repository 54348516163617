import React, { useEffect, useRef } from "react";
import {
  RadioButton1,
  RadioButton2,
  RadioButton3,
  RadioButton4,
  RadioButton5,
  RadioButton6,
} from "./StyledComponents";
import { useRecoilState } from "recoil";
import { selectedTestState } from "../recoil/atoms/inputState";

export default function TabContent({
  t,
  index,
  content,
  focusQuestion,
  setFocusQuestion,
  allQuestionNum,
  selectIndex,
  setSelectIndex,
  isLast,
}) {
  const [testState, setTestState] = useRecoilState(selectedTestState);

  const divRefs = useRef([]);
  useEffect(() => {
    divRefs.current = Array.from({ length: allQuestionNum }, () =>
      React.createRef()
    );
  }, [allQuestionNum]);

  useEffect(() => {
    if (testState.length !== 0) {
      setFocusQuestion(selectIndex + 1);
    }
  }, [setFocusQuestion, selectIndex, testState]);

  return (
    <div className={`py-[45px] text-[#1E274D] text-[0.75rem] ${isLast ? "" : "border-b-[2px] border-solid border-[#ddd]"} relative`}>
      <div
        ref={divRefs.current[index]}
        className={`${
          index !== focusQuestion
            ? "w-full h-full opacity-100"
            : "w-full h-full opacity-0"
        } bg-[rgba(255,255,255,0.7)] absolute top-0 left-0 transition-all duration-700 pointer-events-none`}
      />

      <p className="px-[20px] box-border text-[16px] font-[700] leading-[25px]">
        {t}
      </p>

      <form className="w-full mt-5 px-4 box-border flex justify-between items-center">
        <div className="form-check flex flex-col items-center">
          <label>
            <RadioButton1
              focusQuestion={focusQuestion}
              index={index}
              id={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}-1`}
              type="radio"
              name={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}`}
              value="option1"
              onChange={(e) => {
                setTestState((prev) => {
                  const existingIndex = prev.findIndex(
                    (item) => item.questionNum === index
                  );
                  if (existingIndex > -1) {
                    const updated = [...prev];
                    updated[existingIndex] = {
                      questionNum: index,
                      state: 1,
                    };
                    return updated;
                  } else {
                    return [...prev, { questionNum: index, state: 1 }];
                  }
                });

                setSelectIndex(index);

                divRefs.current[focusQuestion]?.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              checked={
                testState.filter(
                  (tendency) => tendency.questionNum === index
                )[0]?.state === 1
                  ? true
                  : false
              }
            />
          </label>
          <p className="text-[11px] font-[400] leading-[20px] tracking-[-0.44px] text-center">매우<br />아니다</p>
        </div>

        <div className="form-check flex flex-col items-center">
          <label>
            <RadioButton2
              focusQuestion={focusQuestion}
              index={index}
              id={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}-2`}
              type="radio"
              name={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}`}
              value="option2"
              onChange={(e) => {
                setTestState((prev) => {
                  const existingIndex = prev.findIndex(
                    (item) => item.questionNum === index
                  );
                  if (existingIndex > -1) {
                    const updated = [...prev];
                    updated[existingIndex] = {
                      questionNum: index,
                      state: 2,
                    };
                    return updated;
                  } else {
                    return [...prev, { questionNum: index, state: 2 }];
                  }
                });

                setSelectIndex(index);

                divRefs.current[focusQuestion]?.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              checked={
                testState.filter(
                  (tendency) => tendency.questionNum === index
                )[0]?.state === 2
                  ? true
                  : false
              }
            />
          </label>
          <p className="text-[11px] font-[400] leading-[20px] tracking-[-0.44px] text-center mt-[3px]">아니다<br />&nbsp;</p>
        </div>

        <div className="form-check flex flex-col items-center">
          <label>
            <RadioButton3
              focusQuestion={focusQuestion}
              index={index}
              id={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}-3`}
              type="radio"
              name={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}`}
              value="option3"
              onChange={(e) => {
                setTestState((prev) => {
                  const existingIndex = prev.findIndex(
                    (item) => item.questionNum === index
                  );
                  if (existingIndex > -1) {
                    const updated = [...prev];
                    updated[existingIndex] = {
                      questionNum: index,
                      state: 3,
                    };
                    return updated;
                  } else {
                    return [...prev, { questionNum: index, state: 3 }];
                  }
                });

                setSelectIndex(index);

                divRefs.current[focusQuestion]?.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              checked={
                testState.filter(
                  (tendency) => tendency.questionNum === index
                )[0]?.state === 3
                  ? true
                  : false
              }
            />
          </label>
          <p className="text-[11px] font-[400] leading-[20px] tracking-[-0.44px] text-center mt-[8px]">약간<br />아니다</p>
        </div>

        <div className="form-check flex flex-col items-center">
          <label>
            <RadioButton4
              focusQuestion={focusQuestion}
              index={index}
              id={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}-4`}
              type="radio"
              name={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}`}
              value="option4"
              onChange={(e) => {
                setTestState((prev) => {
                  const existingIndex = prev.findIndex(
                    (item) => item.questionNum === index
                  );
                  if (existingIndex > -1) {
                    const updated = [...prev];
                    updated[existingIndex] = {
                      questionNum: index,
                      state: 4,
                    };
                    return updated;
                  } else {
                    return [...prev, { questionNum: index, state: 4 }];
                  }
                });

                setSelectIndex(index);

                divRefs.current[focusQuestion]?.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              checked={
                testState.filter(
                  (tendency) => tendency.questionNum === index
                )[0]?.state === 4
                  ? true
                  : false
              }
            />
          </label>
          <p className="text-[11px] font-[400] leading-[20px] tracking-[-0.44px] text-center mt-[8px]">약간<br />그렇다</p>
        </div>

        <div className="form-check flex flex-col items-center">
          <label>
            <RadioButton5
              focusQuestion={focusQuestion}
              index={index}
              id={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}-5`}
              type="radio"
              name={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}`}
              value="option5"
              onChange={(e) => {
                setTestState((prev) => {
                  const existingIndex = prev.findIndex(
                    (item) => item.questionNum === index
                  );
                  if (existingIndex > -1) {
                    const updated = [...prev];
                    updated[existingIndex] = {
                      questionNum: index,
                      state: 5,
                    };
                    return updated;
                  } else {
                    return [...prev, { questionNum: index, state: 5 }];
                  }
                });

                setSelectIndex(index);

                divRefs.current[focusQuestion]?.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              checked={
                testState.filter(
                  (tendency) => tendency.questionNum === index
                )[0]?.state === 5
                  ? true
                  : false
              }
            />
          </label>
          <p className="text-[11px] font-[400] leading-[20px] tracking-[-0.44px] text-center mt-[3px]">그렇다<br />&nbsp;</p>
        </div>

        <div className="form-check flex flex-col items-center">
          <label>
            <RadioButton6
              focusQuestion={focusQuestion}
              index={index}
              id={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}-6`}
              type="radio"
              name={`${
                content === "personality" ? "pet-tendency" : "pet_relationship"
              }-${index}`}
              value="option6"
              onChange={(e) => {
                setTestState((prev) => {
                  const existingIndex = prev.findIndex(
                    (item) => item.questionNum === index
                  );
                  if (existingIndex > -1) {
                    const updated = [...prev];
                    updated[existingIndex] = {
                      questionNum: index,
                      state: 6,
                    };
                    return updated;
                  } else {
                    return [...prev, { questionNum: index, state: 6 }];
                  }
                });

                setSelectIndex(index);

                divRefs.current[focusQuestion]?.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              checked={
                testState.filter(
                  (tendency) => tendency.questionNum === index
                )[0]?.state === 6
                  ? true
                  : false
              }
            />
          </label>
          <p className="text-[11px] font-[400] leading-[20px] tracking-[-0.44px] text-center">매우<br />그렇다</p>
        </div>
      </form>
    </div>
  );
}
