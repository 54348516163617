import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../components-v2/Button";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  loadingState
} from "../recoil/atoms/generalState";
import {
  healthDetailResultModalOpenState,
  testResultState,
} from "../recoil/atoms/inputState";
import axios from "axios";
import HealthDetailResultModal from "../components/HealthDetailResultModal";
import Slider from "react-slick";
import { calculateAge } from "../utilities/common";

export default function HealthResult() {
  const [searchParams] = useSearchParams();

  const [existsResultPet, setExistsResultPet] = useState(null);
  const [testResult, setTestResult] = useRecoilState(testResultState);
  const setLoading = useSetRecoilState(loadingState);

  const navigate = useNavigate();
  const location = useLocation();
  const selectMyPet = location.state?.selectMyPet || existsResultPet;

  const [modalOpen, setModalOpen] = useRecoilState(
    healthDetailResultModalOpenState
  );
  const [modalIndex, setModalIndex] = useState(0);

  useEffect(() => {
    const qsPetId = searchParams.get("petId");
    if (qsPetId) {
      loadExistsResult(qsPetId);
    }
  }, []);

  const loadExistsResult = async (qsPetId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://pppet-ent-server.vercel.app/exists-result/${qsPetId}/health`
      );
      const data = response.data;
      setTestResult([data.result]);
      setExistsResultPet(data.pet);
    } catch (error) {
      console.info('Perhaps a timing issue may be causing the error if the scan has just finished.');
    }
    setLoading(false);
  };

  const handleShareButtonClick = (e) => {
    window.Kakao.Share.sendDefault({
      objectType: 'text',
      text:
        '[펫펫펫 스마트 건강검진]\n우리 아이의 스마트 건강검진 결과를 확인해보세요!',
      link: {
        mobileWebUrl: `https://ent.pppet.shop/health-result?petId=${selectMyPet._id}`,
        webUrl: `https://ent.pppet.shop/health-result?petId=${selectMyPet._id}`,
      },
    });
  };

  const serverResult = testResult[0];

  const convertServerResult = (serverResult) => {
    if (serverResult == null) {
      return {
        totalScore: 0,
        totalComment: '',
        detail: undefined,
      };
    }

    const partNames = [
      "위/장",
      "신장/방광",
      "뼈/관절",
      "눈 건강",
      "피부/귀",
      "기관지",
      "치아",
    ];

    return {
      totalScore: serverResult.totalScore,
      totalComment: serverResult.result,
      detail: partNames.map((partName, i) => {
        const level =
          serverResult.partialScore[i] >= 80
            ? "양호"
            : serverResult.partialScore[i] >= 50
            ? "주의"
            : "위험";

        return {
          partName,
          score: serverResult.partialScore[i],
          level,
          title: serverResult.partialResult[i].title,
          contents: [
            {
              title: serverResult.partialResult[i].title,
              text: serverResult.partialResult[i].text,
            },
            {
              title: "예방법",
              text: serverResult.partialResult[i].prevention,
            },
            {
              title: "관리 방법",
              text: serverResult.partialResult[i].management,
            },
          ],
        };
      }),
    };
  };

  const result = convertServerResult(serverResult);

  var petAge = calculateAge(selectMyPet?.birthday);
  const getPercentLevel = (value) => {
    if (value >= 80) {
      return '양호';
    } else if (value >= 50) {
      return '주의';
    } else {
      return '위험';
    }
  };

  return (
    <div>
      <div className="w-full px-[20px] pt-[33px] box-border text-[#1E274D] relative bg-[#FAFAFA]">
        <h1 className="text-[25px] font-[700] leading-[32px]">
          {selectMyPet?.name}의
          <br />
          스마트 건강 검진 결과
        </h1>

        <div className="w-full mt-[17px]">
          <div>
            <div>
              <div className="w-full bg-white p-[20px] box-border border border-solid border-[#ddd] rounded-[23px]">
                <div className="w-full">
                  <div className="flex items-center gap-x-[13px]">
                    <h1 className="text-[22px] font-[700] leading-[28px]">종합 점수</h1>
                    <p
                      className={`${
                        {
                          "양호": "bg-[#90D24D]",
                          "주의": "bg-[#FDDC00]",
                          "위험": "bg-[#FF5900]",
                        }[getPercentLevel(result.totalScore)]
                      } py-[1px] px-5 rounded-full text-white`}
                    >
                      {getPercentLevel(result.totalScore)}
                    </p>
                  </div>

                  <div className="flex items-center gap-x-[19px] mt-[11px]">
                    <h1
                      className={`${
                        {
                          "양호": "text-[#90D24D]",
                          "주의": "text-[#FDDC00]",
                          "위험": "text-[#FF5900]",
                        }[getPercentLevel(result.totalScore)]
                      } text-[35px] font-[700] leading-[24px]`}
                    >{`${result.totalScore}점`}</h1>

                    <h1
                      className={`${
                        {
                          "양호": "text-[#90D24D]",
                          "주의": "text-[#FDDC00]",
                          "위험": "text-[#FF5900]",
                        }[getPercentLevel(result.totalScore)]
                      } text-[19px] font-[700] leading-[24px]`}
                    >
                      {
                        {
                          "양호": "아주 건강해요!",
                          "주의": "건강에 관심이 필요해요!",
                          "위험": "정밀검진이 시급해요!",
                        }[getPercentLevel(result.totalScore)]
                      }
                    </h1>
                  </div>
                </div>

                <ul className="w-full mt-[14px] flex items-center text-[#888] text-[14px] font-[500] leading-[22px]">
                  <li className="pr-[8px] mr-[8px] relative flex items-center">
                    <p>{`${selectMyPet?.sex === "F" ? "여아" : "남아"}`}</p>
                    <span className="absolute right-0 h-[8px] w-[1px] bg-[#ddd]" />
                  </li>
                  <li className="pr-[8px] mr-[8px] relative flex items-center">
                    <p>{`${petAge.years > 0 ? petAge.years + "살" : ""} ${petAge.months}개월`}</p>
                    <span className="absolute right-0 h-[8px] w-[1px] bg-[#ddd]" />
                  </li>
                  <li className="pr-[8px] mr-[8px] relative flex items-center">
                    <p>{selectMyPet?.weight}kg</p>
                    <span className="absolute right-0 h-[8px] w-[1px] bg-[#ddd]" />
                  </li>
                  <li>중성화 수술 {selectMyPet?.neutering === "N" ? "안함" : "완료"}</li>
                </ul>
              </div>

              <p className="mt-[7px] text-[14px] text-[#888] font-[500] leading-[22px]">
                * 정확한 진단은 수의사님의 상담이 필요합니다.
              </p>
            </div>

            <div className="w-full mt-[60px]">
              <h1 className="text-[20px] font-[700] leading-[28px] pb-[7px]">
                부위별로 꼼꼼하게 <br />
                건강상태를 확인할 수 있어요.
              </h1>

              {result.detail?.map((detail, index) => {
                return (
                  <div className="w-full mt-[17px] px-[20px] py-[22px] box-border border-[1px] border-solid border-[#ddd] rounded-[23px] flex bg-white">
                    <div className="w-[calc(100%_-_64px_-_16px)] mr-[16px]">
                      <div className="flex items-center gap-x-[4px]">
                        <div className="w-[30px] h-[24px]">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/image/icon/${["stomach", "kidney", "bone", "eye", "skin", "bronchial_tubes", "tooth", ][index]}.png`
                            }
                            className="h-[24px]"
                          />
                        </div>

                        <h1 className="text-[20px] font-[700] leading-[24px]">
                          {detail.partName}
                        </h1>
                      </div>

                      <p className="mt-[8px] text-[16px] font-[500] leading-[20px]">
                        {detail.title}
                      </p>
                    </div>

                    <div className="w-[64px] mt-[2px] flex flex-col items-center">
                      <p
                        className={`${
                          {
                            "양호": "bg-[#90D24D]",
                            "주의": "bg-[#FDDC00]",
                            "위험": "bg-[#FF5900]",
                          }[detail.level]
                        } w-[45px] h-[45px] rounded-full text-white flex items-center justify-center text-[16px] font-[500] leading-[24px]`}
                      >
                        {detail.level}
                      </p>

                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setModalOpen(true);
                          setModalIndex(index);
                        }}
                        className="mt-[8px] text-[#5D80FD] text-[14px] text-[700] leading-[20px]"
                      >
                        <p>자세히 보기</p>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="mt-[20px]">
          {
            location.state?.selectMyPet?._id !== existsResultPet?._id ? (
              <Button
                onClick={() => navigate('/relationship')}
              >
                다시 해보기
              </Button>
            ) : (
              <Button
                onClick={handleShareButtonClick}
                additionalClass="flex justify-center gap-x-[8px]"
              >
                <img src="/image/kakaotalk-icon.png" className="w-[24px] h-[24px]" />
                <span>
                  결과 공유하기
                </span>
              </Button>
            )
          }
        </div>
      </div>

      <div className="bg-[#FAFAFA]">
        <div className="w-full pt-[30px] px-[20px]">
          <div className="w-full flex items-center text-[1rem]">
            <img
              src={process.env.PUBLIC_URL + "./image/icon/dog.png"}
              alt="dog"
            />
            <p className="ml-3 font-semibold">이런 검사는 어떠세요?</p>
          </div>
        </div>
        <div className="py-[12px]">
          <Slider className="slider variable-width" dots={false} infinite={true} speed={500} slidesToShow={1} slidesToScroll={1} variableWidth={true} arrows={false}>
            <div style={{ width: 330 }}>
              <a href="/personality">
                <img className="ml-4" src="/image/recommendation-boxs/personality.png" width={316} />
              </a>
            </div>
            <div style={{ width: 330 }}>
              <a href="/relationship">
                <img className="ml-4" src="/image/recommendation-boxs/relationship.png" width={316} />
              </a>
            </div>
          </Slider>
        </div>
      </div>

      {modalOpen && (
        <HealthDetailResultModal
          pet={selectMyPet}
          details={result.detail}
          modalIndex={modalIndex}
        />
      )}
    </div>
  );
}
