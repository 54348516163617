import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { selectedContentsState } from "../recoil/atoms/generalState";
import {
  selectedTestState,
  inputPetSelectNeutralization,
} from "../recoil/atoms/inputState";
import { authState } from "../recoil/atoms/authState";

export default function SideBar({ sideBarDisplay, setSideBarDisplay }) {
  const navigate = useNavigate();

  const auth = useRecoilValue(authState);
  const [selectedContents] = useRecoilState(selectedContentsState);
  const setTestState = useSetRecoilState(selectedTestState);
  const setInputSelectNeutralization = useSetRecoilState(
    inputPetSelectNeutralization
  );

  const [bannerIndex, setBannerIndex] = useState(1);

  useEffect(() => {
    if (sideBarDisplay === true) {
      setBannerIndex(Math.floor(Math.random() * 4));
    }
  }, [sideBarDisplay]);

  const logout = () => {
    window.localStorage.removeItem('auth');
    window.location.href = '/';
  };

  return (
    <div
      className={`${
        sideBarDisplay ? "opacity-100 z-[9999]" : "opacity-0 z-[-99]"
      } fixed top-0 left-0 w-full h-[100dvh] bg-[rgba(0,0,0,0.6)] flex justify-end transition-all duration-700`}
    >
      <div
        className={sideBarDisplay ? "w-1/3" : "w-0"}
        onClick={() => setSideBarDisplay(false)}
      />
      <div
        className={`${
          sideBarDisplay ? "w-[280px]" : "w-0"
        } h-full bg-white flex flex-col justify-between transition-all duration-500`}
      >
        <div
          className={`h-full flex flex-col justify-between transition-all duration-200 ${
            sideBarDisplay ? "delay-300 opacity-100" : "delay-0 opacity-0"
          }`}
        >
          <div className="w-full">
            <div className="w-full py-[9px] px-[16px] box-border flex justify-between overflow-hidden">
              <h1 className="text-[18px] font-[700] leading-[26px]">
                펫펫펫 엔터테인먼트
              </h1>
              <button onClick={() => setSideBarDisplay(false)}>
                <img
                  src={process.env.PUBLIC_URL + `/image/icon/close.png`}
                  className="w-[24px] h-[24px]"
                  alt="close"
                />
              </button>
            </div>
            <ul className="w-full p-[16px] box-border">
              <li
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/personality");
                  setSideBarDisplay(false);
                  setTestState([]);
                  setInputSelectNeutralization([]);
                }}
                className={`${
                  selectedContents === "personality"
                    ? "bg-opacity-100"
                    : "bg-opacity-0"
                } w-full px-[8px] py-[12px] mb-[10px] box-border bg-[#F4F4F4] rounded-[8px] hover:bg-opacity-100 hover:font-[600] flex items-center gap-x-2 cursor-pointer transition-all duration-700 text-[16px] leading-[24px]`}
              >
                {selectedContents === "personality" ? (
                  <img
                    src={process.env.PUBLIC_URL + `/image/dog-icon-enable.png`}
                    className="w-[24px] h-[24px]"
                    alt="icon"
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + `/image/dog-icon-disable.png`}
                    className="w-[24px] h-[24px]"
                    alt="icon"
                  />
                )}

                <p
                  className={`${selectedContents === "personality" ? "font-[600]" : ""}`}
                >
                  댕BTI 검사
                </p>
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/relationship");
                  setSideBarDisplay(false);
                  setTestState([]);
                  setInputSelectNeutralization([]);
                }}
                className={`${
                  selectedContents === "relationship"
                    ? "bg-opacity-100"
                    : "bg-opacity-0"
                } w-full px-[8px] py-[12px] mb-[10px] box-border bg-[#F4F4F4] rounded-[8px] hover:bg-opacity-100 hover:font-[600] flex items-center gap-x-2 cursor-pointer transition-all duration-700 text-[16px] leading-[24px]`}
              >
                {selectedContents === "relationship" ? (
                  <img
                    src={process.env.PUBLIC_URL + `/image/heart-icon-enable.png`}
                    className="w-[24px] h-[24px]"
                    alt="icon"
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + `/image/heart-icon-disable.png`}
                    className="w-[24px] h-[24px]"
                    alt="icon"
                  />
                )}

                <p
                  className={`${selectedContents === "relationship" ? "font-[600]" : ""}`}
                >
                  반려가족 궁합 검사
                </p>
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/health");
                  setSideBarDisplay(false);
                  setTestState([]);
                  setInputSelectNeutralization([]);
                }}
                className={`${
                  selectedContents === "health"
                    ? "bg-opacity-100"
                    : "bg-opacity-0"
                } w-full px-[8px] py-[12px] mb-[10px] box-border bg-[#F4F4F4] rounded-[8px] hover:bg-opacity-100 hover:font-[600] flex items-center gap-x-2 cursor-pointer transition-all duration-700 text-[16px] leading-[24px]`}
              >
                {selectedContents === "health" ? (
                  <img
                    src={process.env.PUBLIC_URL + `/image/hospital-icon-enable.png`}
                    className="w-[24px] h-[24px]"
                    alt="icon"
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + `/image/hospital-icon-disable.png`}
                    className="w-[24px] h-[24px]"
                    alt="icon"
                  />
                )}

                <p
                  className={`${selectedContents === "health" ? "font-[600]" : ""}`}
                >
                  강아지 스마트 건강검진
                </p>
              </li>
            </ul>
          </div>

          <div className="w-full mb-[34px]">
            <div className="w-full px-[16px] py-[24px] box-border">
              {[
                (
                  <a href="https://pppet.shop" target="_blank">
                    <img src="/image/main-menu-banner-1.png" className="w-[250px] h-[130px]" />
                  </a>
                ),
                (
                  <a href="https://pppet.shop" target="_blank">
                    <img src="/image/main-menu-banner-2.png" className="w-[250px] h-[130px]" />
                  </a>
                ),
                (
                  <a href="https://pppet.shop" target="_blank">
                    <img src="/image/main-menu-banner-3.png" className="w-[250px] h-[130px]" />
                  </a>
                ),
                (
                  <a href="https://pppet.shop" target="_blank">
                    <img src="/image/main-menu-banner-4.png" className="w-[250px] h-[130px]" />
                  </a>
                ),
              ][bannerIndex]}
            </div>

            {
              auth && (
                <div className="w-full px-[20px] box-border flex items-center justify-start gap-x-[8px]">
                  <img
                    src={process.env.PUBLIC_URL + `/image/logout-icon.png`}
                    className="w-[18px] h-[18px]"
                    alt="move"
                  />
                  <a onClick={logout} className="underline underline-offset-2 text-[16px] font-[500] leading-[24px]" href="#logout">
                    로그아웃
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
