import TabContent from "./TabContent";
import { useRecoilValue } from "recoil";
import { selectedTabState } from "../recoil/atoms/inputState";
import { useState } from "react";

export default function Tabs({
  error,
  test,
  content,
  focusQuestion,
  setFocusQuestion,
  allQuestionNum,
}) {
  const selectedTab = useRecoilValue(selectedTabState);
  const [selectIndex, setSelectIndex] = useState(0);

  return (
    <div>
      <div className="w-full box-border">
        {error && <p>Error is occurred...!</p>}

        {selectedTab === 0 && (
          <div>
            {test &&
              test[0]?.map((t, index) => {
                return (
                  <TabContent
                    t={t}
                    key={index}
                    index={index}
                    content={content}
                    focusQuestion={focusQuestion}
                    setFocusQuestion={setFocusQuestion}
                    allQuestionNum={allQuestionNum}
                    selectIndex={selectIndex}
                    setSelectIndex={setSelectIndex}
                    isLast={test[0].length === (index + 1)}
                  />
                );
              })}
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            {test &&
              test[1]?.map((t, index) => {
                return (
                  <TabContent
                    t={t}
                    key={index + test[0]?.length}
                    index={index + test[0]?.length}
                    content={content}
                    focusQuestion={focusQuestion}
                    setFocusQuestion={setFocusQuestion}
                    allQuestionNum={allQuestionNum}
                    selectIndex={selectIndex}
                    setSelectIndex={setSelectIndex}
                    isLast={test[1].length === (index + 1)}
                  />
                );
              })}
          </div>
        )}
        {selectedTab === 2 && (
          <div>
            {test &&
              test[2]?.map((t, index) => {
                return (
                  <TabContent
                    t={t}
                    key={index + test[0]?.length + test[1]?.length}
                    index={index + test[0]?.length + test[1]?.length}
                    content={content}
                    focusQuestion={focusQuestion}
                    setFocusQuestion={setFocusQuestion}
                    allQuestionNum={allQuestionNum}
                    selectIndex={selectIndex}
                    setSelectIndex={setSelectIndex}
                    isLast={test[2].length === (index + 1)}
                  />
                );
              })}
          </div>
        )}
        {selectedTab === 3 && (
          <div>
            {test &&
              test[3]?.map((t, index) => {
                return (
                  <TabContent
                    t={t}
                    key={
                      index +
                      test[0]?.length +
                      test[2]?.length +
                      test[3]?.length
                    }
                    index={
                      index +
                      test[0]?.length +
                      test[1]?.length +
                      test[2]?.length
                    }
                    content={content}
                    focusQuestion={focusQuestion}
                    setFocusQuestion={setFocusQuestion}
                    allQuestionNum={allQuestionNum}
                    selectIndex={selectIndex}
                    setSelectIndex={setSelectIndex}
                    isLast={test[3].length === (index + 1)}
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
