import {atom} from "recoil";

export const selectedPetState = atom({
  key: "selectedPetState",
  default: {
    petType: "dog",
    name: '',
    breed: '',
    breedType: '',
    sex: '',
    birthday: '',
    weight: '',
    neutering: '',
  }
});
