// atom : Recoil에서 상태를 정의하는 개념.
// 컴포넌트에서 구독과 업데이트가 가능하며 atom의 값이 변경될 시 구독하고 있는 컴포넌트가 리렌더링됨.

import { atom } from "recoil";

export const inputPetNameState = atom({
  key: "inputPetNameState",
  default: "",
});

export const inputPetOwnerNameState = atom({
  key: "inputPetOwnerNameState",
  default: "",
});

export const inputPetOwnerBirthYearState = atom({
  key: "inputPetOwnerBirthYearState",
  default: "",
});

export const inputPetOwnerBirthMonthState = atom({
  key: "inputPetOwnerBirthMonthState",
  default: "",
});

export const inputPetOwnerBirthDayState = atom({
  key: "inputPetOwnerBirthDayState",
  default: "",
});

export const inputPetOwnerMbtiState = atom({
  key: "inputPetOwnerMbtiState",
  default: "",
});

export const inputPetTypeState = atom({
  key: "inputPetTypeState",
  default: "",
});

export const inputPetBirthYearState = atom({
  key: "inputPetBirthYearState",
  default: "",
});

export const inputPetBirthMonthState = atom({
  key: "inputPetBirthMonthState",
  default: "",
});

export const inputPetBirthDayState = atom({
  key: "inputPetBirthDayState",
  default: "",
});

export const inputPetWeightState = atom({
  key: "inputPetWeightState",
  default: "",
});

export const inputPetSelectSex = atom({
  key: "inputPetSelectSex",
  default: "",
});

export const inputPetOwnerSelectSex = atom({
  key: "inputPetOwnerSelectSex",
  default: "",
});

export const inputPetOwnerSelectRole = atom({
  key: "inputPetOwnerSelectRole",
  default: "",
});

export const inputPetSelectNeutralization = atom({
  key: "inputPetSelectNeutralization",
  default: "",
});

export const inputPetSelectBreedType = atom({
  key: "inputPetSelectBreedType",
  default: "",
});

export const selectedTabState = atom({
  key: "selectedTabState",
  default: 0,
});

export const selectedTestState = atom({
  key: "selectedTestState",
  default: [],
});

export const selectedMedicalExaminationState = atom({
  key: "selectedMedicalExaminationState",
  default: [],
});

export const selectedPetIdState = atom({
  key: "selectedPetIdState",
  default: "",
});

export const testResultState = atom({
  key: "testResultState",
  default: [],
});

export const healthDetailResultModalOpenState = atom({
  key: "healthDetailResultModalOpenState",
  default: false,
});

export const blockHealthTestTransitionState = atom({
  key: "blockHealthTestTransitionState",
  default: false,
});
