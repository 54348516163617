export const getBbtiAlias = (dbti) => {
  const dbtiMap = {
    AIEC: "빠꾸 없는 직진",
    AIEF: "자유로운 영혼",
    AITC: "마이웨이 인싸",
    AITF: "정 많은 츤데레",
    ASEC: "호기심 만땅 장꾸",
    ASEF: "친해지면 수다쟁이",
    ASTC: "낭만을 아는 포근이",
    ASTF: "분위기 메이커",
    MIEC: "리틀 나르시스트",
    MIEF: "기복 심한 소울리스",
    MITC: "눈치 빠른 탐정",
    MITF: "배려 만땅 신사",
    MSEC: "철벽 너드",
    MSEF: "원리원칙 똥고집",
    MSTC: "똑똑한 사고뭉치",
    MSTF: "겉차속따 포커페이스",
  };
  return dbtiMap[dbti.toUpperCase()];
};
