import React, { useEffect } from "react";
import { blockHealthTestTransitionState, selectedMedicalExaminationState } from "../recoil/atoms/inputState";
import { useRecoilValue, useSetRecoilState } from "recoil";

export default function MedicalExamination5inA({
  index,
  datas,
  isCheckedList,
  setIsCheckedList,
  totalIndex,
  petMedicalExamination,
  count,
  isCheckList,
  setIsCheckList,
  setButtonDisabledFalse,
  setSolvedQuestionNum,
}) {
  const setMedicalExaminationState = useSetRecoilState(
    selectedMedicalExaminationState
  );
  const blockHealthTestTransition = useRecoilValue(blockHealthTestTransitionState);

  const handleChange = (e, idx) => {
    if (count + 1 !== petMedicalExamination.length) {
      // 마지막 문제가 아닐 때
      if (e.currentTarget.checked) {
        if (isCheckedList && !isCheckedList?.includes(idx)) {
          setIsCheckedList((prev) => [...prev, idx]);
        }

        if (isCheckList && !isCheckList?.includes(idx)) {
          // isCheckedList(5inA 문제에서 내가 선택한 답들을 담아주는 배열)에 idx가 담겨 있지 않을 경우
          setIsCheckList((prev) => [...prev, idx]);

          // isCheckList 갯수가 0이고 idx 선택지를 처음 선택할 때 +1
          if (isCheckList && isCheckList?.length === 0) {
            setSolvedQuestionNum((prev) => prev + 1);
          }
        } else if (isCheckList && isCheckList?.includes(idx)) {
          // isCheckedList에 idx가 이미 있는 경우
          setIsCheckList((prev) => prev?.filter((check) => check !== idx));

          // isCheckList(5inA 문제에서 내가 선택한 답들을 담아주는 배열 => 로컬스토리지랑 연계하여 다음 문제로 넘어가도 데이터가 유지되도록) 갯수가 0이고
          // 선택한 선택지를 해제할 때 -1
          if (isCheckList && isCheckList?.length === 1) {
            setSolvedQuestionNum((prev) => prev - 1);
          }
        }
      } else if (!e.currentTarget.checked) {
        if (isCheckedList && isCheckedList?.includes(idx)) {
          setIsCheckedList((prev) => prev?.filter((check) => check !== idx));
        }

        if (isCheckList && !isCheckList?.includes(idx)) {
          setIsCheckList((prev) => [...prev, idx]);

          if (isCheckList && isCheckList?.length === 0) {
            setSolvedQuestionNum((prev) => prev + 1);
          }
        } else if (isCheckList && isCheckList?.includes(idx)) {
          setIsCheckList((prev) => prev?.filter((check) => check !== idx));

          if (isCheckList && isCheckList?.length === 1) {
            setSolvedQuestionNum((prev) => prev - 1);
          }
        }
      }
    } else if (count + 1 === petMedicalExamination.length) {
      // 마지막 문제일 때
      if (e.currentTarget.checked) {
        setMedicalExaminationState((prev) => {
          // 배열의 해당되는 인덱스에 배열이 없을 때 새로운 배열 생성 후 선택지 인덱스 추가
          if (!prev[totalIndex] && !prev[totalIndex]?.includes(0)) {
            return [...prev, [idx]];
          } else if (prev[totalIndex]?.length > 0) {
            // 배열의 해당되는 인덱스에 배열이 있을 때
            // 선택한 인덱스가 이미 해당 배열에 있는 경우, 해당 인덱스를 배열에서 제거
            if (prev[totalIndex]?.includes(idx)) {
              return prev?.map((item, index) =>
                index === totalIndex
                  ? item?.filter((value) => value !== idx)
                  : item
              );
            } else if (!prev[totalIndex]?.includes(idx)) {
              // 선택한 인덱스가 해당 배열에 없는 경우, 해당 인덱스를 배열에 추가
              return prev?.map((item, index) =>
                index === totalIndex ? [...item, idx] : item
              );
            }
          }
        });

        if (isCheckedList && !isCheckedList?.includes(idx)) {
          setIsCheckedList((prev) => [...prev, idx]);
        }

        if (isCheckedList && !isCheckedList?.includes(idx)) {
          setIsCheckedList((prev) => [...prev, idx]);
        }

        if (isCheckList && !isCheckList?.includes(idx)) {
          setIsCheckList((prev) => [...prev, idx]);

          if (isCheckList && isCheckList?.length === 0) {
            setSolvedQuestionNum((prev) => prev + 1);
          }
        } else if (isCheckList && isCheckList?.includes(idx)) {
          setIsCheckList((prev) => prev?.filter((check) => check !== idx));

          if (isCheckList && isCheckList?.length === 1) {
            setSolvedQuestionNum((prev) => prev - 1);
          }
        }
      } else if (!e.currentTarget.checked) {
        setMedicalExaminationState((prev) => {
          // 배열의 해당되는 인덱스에 배열이 없을 때 새로운 배열 생성 후 선택지 인덱스 추가
          if (!prev[totalIndex]) {
            return [...prev, [idx]];
          } else if (prev[totalIndex]?.length > 0) {
            // 배열의 해당되는 인덱스에 배열이 있을 때
            // 선택한 인덱스가 이미 해당 배열에 있는 경우, 해당 인덱스를 배열에서 제거
            if (prev[totalIndex]?.includes(idx)) {
              return prev?.map((item, index) =>
                index === totalIndex
                  ? item?.filter((value) => value !== idx)
                  : item
              );
            } else if (!prev[totalIndex]?.includes(idx)) {
              // 선택한 인덱스가 해당 배열에 없는 경우, 해당 인덱스를 배열에 추가
              return prev?.map((item, index) =>
                index === totalIndex ? [...item, idx] : item
              );
            }
          }
        });

        if (isCheckedList && isCheckedList?.includes(idx)) {
          setIsCheckedList((prev) => prev?.filter((check) => check !== idx));
        }

        if (isCheckedList && isCheckedList?.includes(idx)) {
          setIsCheckedList((prev) => prev?.filter((check) => check !== idx));
        }

        if (isCheckList && !isCheckList?.includes(idx)) {
          setIsCheckList((prev) => [...prev, idx]);

          if (isCheckList && isCheckList?.length === 0) {
            setSolvedQuestionNum((prev) => prev + 1);
          }
        } else if (isCheckList && isCheckList?.includes(idx)) {
          setIsCheckList((prev) => prev?.filter((check) => check !== idx));

          if (isCheckList && isCheckList?.length === 1) {
            setSolvedQuestionNum((prev) => prev - 1);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (petMedicalExamination[count].selectAnswerType === "5inA") {
      if (isCheckList.length > 0) {
        setButtonDisabledFalse(false);
      } else {
        setButtonDisabledFalse(true);
      }
    }
  }, [setButtonDisabledFalse, isCheckList, count, petMedicalExamination]);

  return (
    <div className="w-full">
      {datas?.map((data, idx) => {
        return (
          <div className="w-full">
            <input
              autocomplete="off"
              className="sr-only"
              type="checkbox"
              id={`health-${idx}`}
              name={`health-${index}`}
              value={`option${idx}`}
              onChange={(e) => handleChange(e, idx)}
              checked={
                isCheckList && !isCheckList?.includes(idx) ? false : true
              }
            />

            <label
              className={`${
                isCheckList && isCheckList?.includes(idx) && "bg-[#5D80FD]"
              } ${
                blockHealthTestTransition ? '' : 'transition-all duration-700'
              } w-full px-[22px] py-[15px] box-border mb-[20px] cursor-pointer text-[#888] border border-solid border-[#ddd] rounded-[23px] flex items-center`}
              htmlFor={`health-${idx}`}
            >
              <span
                className={`${
                  isCheckList && isCheckList?.includes(idx)
                    ? "text-white"
                    : "text-[#888]"
                } ${
                  blockHealthTestTransition ? '' : 'transition-all duration-700'
                } text-[14px] font-[500] leading-[20px]`}
              >
                {data}
              </span>
            </label>
          </div>
        );
      })}
    </div>
  );
}
