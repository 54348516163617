import React, {useEffect, useState} from "react";
import { useSetRecoilState } from "recoil";
import {
  inputPetOwnerSelectRole,
  inputPetOwnerSelectSex,
  inputPetSelectBreedType,
  inputPetSelectNeutralization,
  inputPetSelectSex,
} from "../recoil/atoms/inputState";

export default function InputCheckbox({ inputTitle, datas, defaultValue }) {
  const [isChecked, setIsChecked] = useState("");
  const setInputSelectSex = useSetRecoilState(inputPetSelectSex);
  const setInputSelectOwnerSex = useSetRecoilState(inputPetOwnerSelectSex);
  const setInputSelectOwnerRole = useSetRecoilState(inputPetOwnerSelectRole);
  const setInputSelectNeutralization = useSetRecoilState(
    inputPetSelectNeutralization
  );
  const setInputSelectBreedType = useSetRecoilState(inputPetSelectBreedType);

  useEffect(() => {
    if (inputTitle === '견종 크기') {
      setIsChecked(defaultValue === 'S' ? '소형견' : defaultValue === 'M' ? '중형견' : defaultValue === 'B' ? '대형견' : '')
      setInputSelectBreedType(defaultValue)
    } else if (inputTitle === '성별') {
      setIsChecked(defaultValue === 'M' ? '남아' : defaultValue === 'F' ? '여아' : '')
      setInputSelectBreedType(defaultValue)
    } else if (inputTitle === '중성화 수술 여부') {
      setIsChecked(defaultValue === 'Y' ? '예' : defaultValue === 'N' ? '아니오' : '')
      setInputSelectBreedType(defaultValue)
    }
  }, []);

  const handleChange = (e, data) => {
    if (e.currentTarget.checked) {
      setIsChecked(data);

      if (
        e.currentTarget.value === "남아" ||
        e.currentTarget.value === "여아"
      ) {
        if (e.currentTarget.value === "남아") {
          setInputSelectSex("M");
        } else if (e.currentTarget.value === "여아") {
          setInputSelectSex("F");
        }
      }

      if (
        e.currentTarget.value === "남자" ||
        e.currentTarget.value === "여자"
      ) {
        if (e.currentTarget.value === "남자") {
          setInputSelectOwnerSex("M");
        } else if (e.currentTarget.value === "여자") {
          setInputSelectOwnerSex("F");
        }
      }

      if (
        e.currentTarget.value === "예" ||
        e.currentTarget.value === "아니오"
      ) {
        if (e.currentTarget.value === "예") {
          setInputSelectNeutralization("Y");
        } else if (e.currentTarget.value === "아니오") {
          setInputSelectNeutralization("N");
        }
      }

      switch (e.currentTarget.value) {
        case "소형견":
          setInputSelectBreedType("S");
          break;
        case "중형견":
          setInputSelectBreedType("M");
          break;
        case "대형견":
          setInputSelectBreedType("B");
          break;
      }

      switch (e.currentTarget.value) {
        case "보호자":
          setInputSelectOwnerRole("family");
          break;
        case "대리보호자":
          setInputSelectOwnerRole("sitter");
          break;
        case "친구":
          setInputSelectOwnerRole("friend");
          break;
      }
    }
  };

  return (
    <div>
      <p
        className="text-[14px] font-[500] leading-[20px]"
      >
        {inputTitle}
      </p>

      <div className="mt-[4px] mb-[30px] flex items-center justify-between gap-x-[8px]">
        {datas?.map((data) => {
          return (
            <div className="w-1/2">
              <input
                autocomplete="off"
                className="sr-only"
                type="checkbox"
                id={
                  data === "남아"
                    ? "boy"
                    : data === "여아"
                    ? "girl"
                    : data === "남자"
                    ? "man"
                    : data === "여자"
                    ? "woman"
                    : data === "예"
                    ? "yes"
                    : data === "아니오"
                    ? "no"
                    : data === "소형견"
                    ? "breed_type_s"
                    : data === "중형견"
                    ? "breed_type_m"
                    : data === "대형견"
                    ? "breed_type_b"
                    : data === "보호자"
                    ? "family"
                    : data === "대리보호자"
                    ? "sitter"
                    : data === "친구"
                    ? "friend"
                    : ''
                }
                name={`${
                  data.includes("남아") ||
                  data.includes("여아") ||
                  data.includes("남자") ||
                  data.includes("여자")
                    ? "sex"
                    : data.includes("예") || data.includes("아니오")
                    ? "yesOrNo"
                    : data.includes("소형견") || data.includes("중형견") || data.includes("대형견")
                    ? "breedType"
                    : data.includes("보호자") || data.includes("대리보호자") || data.includes("친구")
                    ? "ownerRole"
                    : ""
                }`}
                value={data}
                onChange={(e) => handleChange(e, data)}
                checked={data !== isChecked ? false : true}
              />

              <label
                className={`${data === isChecked ? "border-[#5D80FD]" : "border-[#ddd]"} ${
                  inputTitle === "견주 성별" ? "rounded-full" : "rounded-full"
                } bg-white w-full py-[8px] cursor-pointer text-[#383838] border-[1px] border-solid transition-all duration-700 flex justify-center items`}
                htmlFor={
                  data === "남아"
                    ? "boy"
                    : data === "여아"
                    ? "girl"
                    : data === "남자"
                    ? "man"
                    : data === "여자"
                    ? "woman"
                    : data === "예"
                    ? "yes"
                    : data === "아니오"
                    ? "no"
                    : data === "소형견"
                    ? "breed_type_s"
                    : data === "중형견"
                    ? "breed_type_m"
                    : data === "대형견"
                    ? "breed_type_b"
                    : data === "보호자"
                    ? "family"
                    : data === "대리보호자"
                    ? "sitter"
                    : data === "친구"
                    ? "friend"
                    : ''
                }
              >
                <span
                  className={`${
                    data === isChecked ? "text-[#5D80FD] font-[700]" : "text-[#ccc] font-[500]"
                  } transition-all duration-700 text-[14px] leading-[22px]`}
                >
                  {data}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
