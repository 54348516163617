import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../components/Header";
import { Outlet, useLocation } from "react-router-dom";
import SideBar from "../components/SideBar";
import { QueryClient, QueryClientProvider } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ScrollRestoration } from "react-router-dom";
import { authState } from "../recoil/atoms/authState";
import {initScrollTriggerState, loadingState} from "../recoil/atoms/generalState";
import {createPortal} from "react-dom";
import Loading from "../components/Loading";
import { Transition } from "@headlessui/react";
import Toast from "../components-v2/Toast";
import { showToastState, toastTextState, toastTypeState } from "../recoil/atoms/toastState";
import { healthDetailResultModalOpenState } from "../recoil/atoms/inputState";

const queryClient = new QueryClient();

export default function Root() {
  const setAuth = useSetRecoilState(authState);
  const loading = useRecoilValue(loadingState);
  const [sideBarDisplay, setSideBarDisplay] = useState(false);
  const [isTopScroll, setIsTopScroll] = useState(true);

  const showToast = useRecoilValue(showToastState);
  const toastText = useRecoilState(toastTextState);
  const toastType = useRecoilState(toastTypeState);

  const isOpenHealthResultPopup = useRecoilValue(healthDetailResultModalOpenState);

  const scrollRef = useRef(null);
  const [initScrollTrigger, setInitScrollTrigger] = useRecoilState(initScrollTriggerState);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(handleInterval, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollTopSmooth();
    }

    const auth = window.localStorage.getItem("auth") ?? "";
    setAuth(auth);

    setInitScrollTrigger(false);
  }, [location, initScrollTrigger]);

  const handleInterval = () => {
    if (scrollRef.current.scrollTop > 0) {
      setIsTopScroll(false);
    } else {
      setIsTopScroll(true);
    }
  };

  const scrollTopSmooth = () => {
    scrollRef.current.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className="w-full h-[100dvh] bg-[#f6f6f6] whitespace-pre-line overflow-hidden">
      <div className="relative w-full max-w-[720px] mx-auto bg-white text-pppet-dark-blue">
        <ScrollRestoration />

        <Header setSideBarDisplay={setSideBarDisplay} />

        <SideBar
          sideBarDisplay={sideBarDisplay}
          setSideBarDisplay={setSideBarDisplay}
        />

        <div
          ref={scrollRef}
          className={`w-full max-w-[720px] ${['/measure-questions', '/health-questions'].includes(location.pathname) ? 'h-[100dvh]' : 'h-[calc(100dvh_-_44px)]'} overflow-scroll scrollbar-hide relative`}
        >
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </div>
      </div>

      <Transition
        show={!isTopScroll && location.pathname.includes("-result") && !isOpenHealthResultPopup}
        className="fixed bottom-[20px] right-[15px]"
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <button onClick={scrollTopSmooth}>
          <img src="/image/goto-top-btn.png" className="w-[99px] h-[43px]" />
        </button>
      </Transition>

      <Transition
        show={loading}
        className="fixed top-0 left-0 w-[100dvw] h-[100dvh] z-[10000]"
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Loading />
      </Transition>

      <Transition
        show={showToast}
        className="transition-all duration-300 fixed left-1/2 translate-x-[-50%]"
        enterFrom="opacity-0 bottom-[70px]"
        enterTo="opacity-100 bottom-[90px]"
        leaveFrom="opacity-100 bottom-[90px]"
        leaveTo="opacity-0 bottom-[110px]"
      >
        <Toast
          type={toastType}
        >
          {toastText}
        </Toast>
      </Transition>
    </div>
  );
}
